import React,{useState} from 'react'

import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


import mlcp1 from '../../folder/images/exclusive/tnr_devine/MLCP-WORK-1.png'
import mlcp2 from '../../folder/images/exclusive/tnr_devine/MLCP-WORK-2.png'

import TNR1 from '../../folder/images/exclusive/tnr_devine/TNR-1.png'
import TNR2 from '../../folder/images/exclusive/tnr_devine/TNR-DIVINE.jpg'

import About1 from '../../folder/images/exclusive/tnr_devine/TNR-3.jpg'
import About2 from '../../folder/images/exclusive/tnr_devine/TNR-3.jpg'
import About3 from '../../folder/images/exclusive/tnr_devine/TNR-View.jpg'
import About4 from '../../folder/images/exclusive/tnr_devine/TNR-5.jpg'

import TNRExterior3 from '../../folder/images/exclusive/tnr_devine/TNR-Exterior-3.jpg'
import TNRExterior4 from '../../folder/images/exclusive/tnr_devine/TNR-Exterior-4.jpg'

import TNRExterior1 from '../../folder/images/exclusive/tnr_devine/TNR-Exterior-1.jpg'
import TNRExterior2 from '../../folder/images/exclusive/tnr_devine/TNR-Exterior-2.jpg'
import TNRHighlights3 from '../../folder/images/exclusive/tnr_devine/TNR-HIGHLIGHTS-3.jpg'

import tnr_drone_view1 from '../../folder/images/exclusive/tnr_devine/DRONE-VIEW-1.png'
import tnr_drone_view2 from '../../folder/images/exclusive/tnr_devine/DRONE-VIEW-2.png'
import tnr_drone_view3 from '../../folder/images/exclusive/tnr_devine/DRONE-VIEW-3.png'
import tnr_drone_view4 from '../../folder/images/exclusive/tnr_devine/DRONE-VIEW.png'

import exterior_view1 from '../../folder/images/exclusive/tnr_devine/TNR-EXTERIOR-VIEW-1.png'
import exterior_view2 from '../../folder/images/exclusive/tnr_devine/TNR-EXTERIOR-VIEW-2.png'
import exterior_view3 from '../../folder/images/exclusive/tnr_devine/TNR-EXTERIOR-VIEW-4.jpg'
import exterior_view4 from '../../folder/images/exclusive/tnr_devine/TNR-EXTERIOR-VIEW.png'

import master_plan from '../../folder/images/exclusive/tnr_devine/TNR-Master-Plan.jpg'
import master_plan1 from '../../folder/images/exclusive/tnr_devine/TNR-Master-Plan-1.jpg'
import stilt_floor_plan from '../../folder/images/exclusive/tnr_devine/TNR-Floor-plan-1.jpg'
import ground_floor_plan from '../../folder/images/exclusive/tnr_devine/TNR-Floor-plan-2.jpg'
import typical_floor_plan from '../../folder/images/exclusive/tnr_devine/TNR-Floor-plan.jpg'
import GoBack from '../../folder/components/GoBack';

function TNRDevine() {

  const bannerArr = [TNRExterior1,TNRExterior2,TNRExterior3,TNRExterior4,tnr_drone_view1,tnr_drone_view2,tnr_drone_view3,tnr_drone_view4]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>TNR DIVINE TECH PARK</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>ELECTRONIC CITY, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>ELECTRONIC CITY OVERVIEW</h6>
        <div className='w-[90%] md:w-[80%] mx-auto'>
          <ul className='md:ml-10 list-disc '>
                <li className='font_normal text-[13px] mb-1'>Electronic City Phase-1 is known as a technology centre and a hub for global IT Companies.</li>
                <li className='font_normal text-[13px] mb-1'>The development of the major MNC’S Campuses has increased the relocation of talented manpower in the surrounding areas of electronic city where hiring technical talents is much easier and available The distinguished business houses in E-City Phase-1 :</li>

                <ul className='ml-10 list-decimal my-5'>
                  <li className='font_normal text-[13px] mb-1'>DXY Technology</li>
                  <li className='font_normal text-[13px] mb-1'>HCL Technologies</li>
                  <li className='font_normal text-[13px] mb-1'>Salarpuria Infozone</li>
                  <li className='font_normal text-[13px] mb-1'>Cyber Park Infosys</li>
                  <li className='font_normal text-[13px] mb-1'>Wipro</li>
                  <li className='font_normal text-[13px] mb-1'>Genpact</li>
                  <li className='font_normal text-[13px] mb-1'>Siemens and So on has generated ample employment opportunities.</li>
                </ul>

                <li className='font_normal text-[13px] mb-1'>The growth of Electronic City Phase-1 has prompted for many reputed residential projects to surround this area few of them are:  Prestige Sunrise Park, PSR Krish Kamal, Prime Environs, Aratt Requizza, and Ajmera Annex.</li>
                <li className='font_normal text-[13px] mb-1'>The connectivity to the Electronic City Elevated Expressway has eased the traffic congestions at various junctions, making is easy to commute faster.</li>
                <li className='font_normal text-[13px] mb-1'>Namma metro service will be coming by 2023.  The Metro line is just under 3 Kms from the proposed site.  Also the Metro line will connect Electronic city Phase-1, Phase-2, Hebbagodi and Huskur Road</li>
                <li className='font_normal text-[13px] mb-1'>The project is surrounded by reputed educational institutions like : Azim Premji    University, Xavier’s Institute of Management and Entrepreneurship (XIME), Silicon Valley High, Delhi Public School, Treamis World School, etc., also some of the top hospitals, shopping malls, restaurants are in close proximity making it an ideal destination.</li>
                <li className='font_normal text-[13px] mb-1'>Electronic City is one of the potential areas to invest because of its rental values, well-developed social infrastructure, and robust connectivity.</li>
          </ul>
        </div>


        <div className='w-[90%] md:w-[75%] justify-between mt-10 md:flex mx-auto'>

          <div className='md:w-[49%] p-5 border border-slate-100 '>
            <h6 className='bg-[#4190ac]/40 mb-2 text-[13px] font_bold p-[4px]'>MAJOR IT & ITES IN ELECTRONIC CITY</h6>
            <img className='w-[100%] object-contain' src={TNR1} />
          </div>
          <div className='md:w-[49%] mt-5 p-5 border border-slate-100 '>
            <h6 className='bg-[#4190ac]/40 mb-2 text-[13px] font_bold p-[4px]'>TNR DIVINE TECH PARK</h6>
            <img className='w-[100%] object-contain' src={TNR2} />
          </div>
        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>ABOUT NEO TOWN</h6>

        <div className='w-[90%] md:w-[80%] mx-auto'>

          <h6 className='font_normal text-[13px] my-4'>“Neo town” is a fully integrated township in Electronics City Phase-1, just 12 minutes from the Silk Board junction. Salient Features of the Project are :</h6>
          <ul className='ml-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'>It is spread over 100 acres, having a mixed development of both residential as well as huge area for commercial development.</li>
              <li className='font_normal text-[13px] mb-1'>Adjacent to Electronic City the World’s-IT outsourcing capital.</li>
              <li className='font_normal text-[13px] mb-1'>Well connected by Metro and Ring Roads, it is in close proximity to many corporate offices</li>
              <li className='font_normal text-[13px] mb-1'>Very easy access to more than 300 big and small companies in the vicinity. Wipro, Siemens, HP, HCL, Schneider, Tech Mahindra, CGI, Bosch, Client to name a few.</li>
              <li className='font_normal text-[13px] mb-1'>Infosys Limited has its world headquarters just 2 kilometers away.</li>
              <li className='font_normal text-[13px] mb-1'>Easy availability of manpower from the nearby residential catchment.</li>
          </ul>
        </div>


        <div className='grid w-[80%] my-10 mx-auto gap-5 grid-cols-1 md:grid-cols-4'>
          <div className='relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px] absolute bottom-1 text-white left-1'>{'CONNECTIVITY'}</h6>
            <img src={About1} />
          </div>
          <div className='relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px] absolute bottom-1 text-white left-1'>{'SURROUNDING VIEW'}</h6>
            <img src={About2} />
          </div>
          <div className='relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px] absolute bottom-1 text-white left-1'>{'TNR DIVINE TECH PARK'}</h6>
            <img src={About3} />
          </div>
          <div className='relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px] absolute bottom-1 text-white left-1'>{'CONNECTIVITY'}</h6>
            <img src={About4} />
          </div>

        </div>



        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>PROPERTY HIGHLIGHTS</h6>
          <div className='w-[90%] md:w-[80%] mx-auto'>
          <ul className='ml-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'>8.5 acres of invigorating landscape.</li>
              <li className='font_normal text-[13px] mb-1'>1 million sq. ft. of state-of-the-art work space.</li>
              <li className='font_normal text-[13px] mb-1'>Convenience of plug and play offices.</li>
              <li className='font_normal text-[13px] mb-1'>Township master plan by world famous architect Andy  Fisher.</li>
              <li className='font_normal text-[13px] mb-1'>Spacious indoor/outdoor and multi-level car parking spaces.</li>
              <li className='font_normal text-[13px] mb-1'>High quality structure.</li>
              <li className='font_normal text-[13px] mb-1'>Leading edge earthquake resistant construction.</li>
          </ul>
          </div>


          <div className='w-[90%] md:w-[80%] md:flex mt-10 mx-auto'>
            <img src={TNRExterior3} className='md:w-[48%] mr-[2%]' />
            <img src={TNRExterior4} className='md:w-[48%] mt-10 md:mt-0 md:ml-[2%]' />
          </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>KEY SPECIFICATIONS</h6>
        <div className='w-[90%] md:w-[80%] mx-auto'>
          <ul className='ml-10 list-disc '>
            <li className='font_normal text-[13px] mb-1'>Plenty of water supply.</li>
            <li className='font_normal text-[13px] mb-1'>MLCP – Multi Level Car Parking.</li>
            <li className='font_normal text-[13px] mb-1'>Excellent ambience with lush green surroundings.</li>
            <li className='font_normal text-[13px] mb-1'>Green building design reduces air conditioning cost considerably.</li>
            <li className='font_normal text-[13px] mb-1'>2 towers aesthetically designed with planned floor layouts and sky-lit atrium.</li>
            <li className='font_normal text-[13px] mb-1'>Equipped with all fire fighting facilities and safety features.</li>
            <li className='font_normal text-[13px] mb-1'>Built with the best materials and construction standards.</li>
            <li className='font_normal text-[13px] mb-1'>Healthy workplace with no noise or air pollution.</li>
          </ul>
        </div>

        <div className='w-[90%] md:w-[80%] md:flex mt-10 mx-auto'>
            <img src={TNRHighlights3} className='md:w-[48%] md:mr-[2%]' />
            <img src={TNRExterior1} className='md:w-[48%] mt-5 md:ml-[2%]' />
          </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>DISTANCE</h6>
        
        <div className='w-[90%] md:w-[80%] mx-auto'>
          <ul className='ml-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>METRO</span> – Upcoming Electronic City Phase-1 – 10 Min</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>BUS</span> – Thirupalya Bus Station – 5 Min</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>MAIN ROAD</span> – Hosur Road – 10 Min</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>TOLL PLAZA</span> – Electronic City Phase-1 – 9 Min</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>RING ROAD</span> – NICE ROAD – 20 Min – NICE road connectivity is in close proximity to Electronic City. (Connectivity from Tumkur Road, Mysore Road, Kanakapura Road and Bannerghatta Road).</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold'>ELCITA CAMPUS</span> – 5 Min ( Infosys Campus, Wipro, Bosch, Tech Mahindra, TCS… Etc…, )</li>
              <li className='font_normal text-[13px] mb-1'><span className='font_bold underline'>DISTANCE FROM MAJOR LOCATIONS</span></li>
        </ul>
        <ul className='ml-20 list-disc mt-5'>
          <li className='font_normal text-[13px] mb-1'><span>MG ROAD – 30 KMS</span></li>
          <li className='font_normal text-[13px] mb-1'><span>HEBBAL – 20 KMS</span></li>
          <li className='font_normal text-[13px] mb-1'><span>WHITEFIELD – 30 KMS</span></li>
          <li className='font_normal text-[13px] mb-1'><span>YELAHANKA – 39 KMS</span></li>
          <li className='font_normal text-[13px] mb-1'><span>MYSORE ROAD – 28 KMS</span></li>
          <li className='font_normal text-[13px] mb-1'><span>BIAL  – 60KMS</span></li>
        </ul>
        </div>
       
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>MLCP WORK</h6>

        <div className='md:flex w-[90%] md:w-[80%] mx-auto'>
          <div className='md:w-[50%]'>
            <img src={mlcp1} className='w-[100%] h-[240px] object-contain md:-ml-[36px]' />
            <img src={mlcp2} className='w-[100%] h-[240px] object-contain' />

          </div>
          <div className='md:w-[50%] md:ml-10'>
            <ul className='ml-10 list-disc'>
             <li className='font_normal text-[14px] mb-2'>Cost savings. Despite a certain initial investment, the rent for land in the future will be low due to the small occupied area. Lack of staff also reduces costs.</li>
             <li className='font_normal text-[14px] mb-2'>Safety. Our modern parking systems provide the best possible protection against theft. Moving a car without a driver, no need for maneuvering when entering a parking lot reduces the number of accidents.</li>
             <li className='font_normal text-[14px] mb-2'>Reduction of parking time. The operation of parking a car in an automated parking lot takes no more than one and a half minutes.</li>
             <li className='font_normal text-[14px] mb-2'>It should also be noted environmental friendliness since the cars in the parking lot move with the engine off, as well as a variety of designs, which allows you to choose a design for any exterior.</li>
            </ul>
          </div>
        </div>


     

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Floor Plans</h6>
        
        <div className='grid grid-cols-2 pb-10 md:grid-cols-5 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>MASTER PLAN</h6>
            <img src={master_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>TNR DIVINE TECH PARK</h6>
            <img src={master_plan1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>STILL FLOOR PLAN</h6>
            <img src={stilt_floor_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>GROUND FLOOR PLAN</h6>
            <img src={ground_floor_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>TYPICAL FLOOR PLAN</h6>
            <img src={typical_floor_plan} />
        </div>

       
        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>REFERENCE – EXTERIOR VIEW</h6>
        <div className='grid grid-cols-1 pb-10 md:grid-cols-2 w-[90%] md:w-[80%] mx-auto gap-5'>
          <img src={exterior_view1} className='w-[100%] md:h-[300px] object-cover' />
          <img src={exterior_view2} className='w-[100%] md:h-[300px] object-cover' />
          <img src={exterior_view3} className='w-[100%] md:h-[300px] object-cover' />
          <img src={exterior_view4} className='w-[100%] md:h-[300px] object-cover' />
          
        </div>


        <div className='w-[90%] md:w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={location_1} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497954.9744177347!2d77.658314!3d12.825612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6c7a9269a0bf%3A0x5083d1c11f90330!2sTNR%20Inizio!5e0!3m2!1sen!2sus!4v1720676179276!5m2!1sen!2sus"  className='w-full h-full'    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>COMMERCIAL DETAILS</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Total Leasable Area – 8,55,198.79 Sqft</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>No. of Floors – Stilt + Ground + 8 Floors + Terrace</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Car Parking Slots – 1554 Slots ( MLCP + Surface + Basement )</h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: ACHUTH GOWDA</h6>
                        <h6 className='font_normal text-[12px]'>achuth.gowda@fidelituscorp.com  </h6>
                    </div>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: JOHN VINOD</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845010178 / john.vinod@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default TNRDevine