import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/kamadhenu/banner1.jpg'
import banner2 from '../../folder/images/exclusive/kamadhenu/banner2.jpg'
import banner3 from '../../folder/images/exclusive/kamadhenu/banner3.jpg'
import first_floor from '../../folder/images/exclusive/kamadhenu/1st-Floor.jpg'
import second_floor from '../../folder/images/exclusive/kamadhenu/2nd-Floor.jpg'
import third_floor from '../../folder/images/exclusive/kamadhenu/3rd-Floor.jpg'
import basement from '../../folder/images/exclusive/kamadhenu/Basement.jpg'
import ground from '../../folder/images/exclusive/kamadhenu/Ground.jpg'
import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'
import sectional_plan from '../../folder/images/exclusive/kamadhenu/Sectional-Plan.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import PrimeLandFCPL1 from '../../folder/images/exclusive/prime_land/Prime-Land-FCPL-3.jpg'
import PrimeLandFCPL2 from '../../folder/images/exclusive/prime_land/Prime-Land-Fidelitus-corp-1.jpeg'
import PrimeLandFCPL3 from '../../folder/images/exclusive/prime_land/Prime-Land-Fidelitus-corp-2.jpeg'

import Land1 from '../../folder/images/exclusive/prime_land/LAND-FOR-SALE-DASANPURA-1.png'
import Land2 from '../../folder/images/exclusive/prime_land/LAND-FOR-SALE-DASANPURA-3.png'
import Land3 from '../../folder/images/exclusive/prime_land/LAND-FOR-SALE-DASANPURA-4.png'
import Land4 from '../../folder/images/exclusive/prime_land/LAND-FOR-SALE-DASANPURA.png'

import LandSketch from '../../folder/images/exclusive/prime_land/LAND-SKETCH.png'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function PrimeLand() {

  const bannerArr = [PrimeLandFCPL1,PrimeLandFCPL2,PrimeLandFCPL3]  

  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>PRIME LAND OPTION FOR SALE</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>DASANPURA, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total  area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>34,848 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Location</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>NH 4, Tumkur Road, Dasanpura</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Ownership</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Single Ownership</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Usage of Land</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className=' flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Current status</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Open Land</h6>
            </div>
           

        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>ROAD CONNECTIVITY</h6>
        
        <div className='grid grid-cols-2 pb-10 md:grid-cols-4 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <img src={Land1} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <img src={Land2} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <img src={Land3} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <img src={Land4} />
        </div>

       
        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>LAND SKETCH</h6>


        <img className='w-[80%] mx-auto'  src={LandSketch} />


        <div className='w-[80%] md:flex items-start mt-10 mx-auto '>
            {/* <img src={LandSketch} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d497460.56667000457!2d77.439278!3d13.073139!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDA0JzIzLjMiTiA3N8KwMjYnMjEuNCJF!5e0!3m2!1sen!2sus!4v1720676691522!5m2!1sen!2sus" className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className=' mt-10 md:mt-0 md:ml-10'>
              
                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2 border-b mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Fidelitus Corp</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / info@fidelituscorp.com  </h6>
                    </div>

                   
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PrimeLand