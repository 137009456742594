import React from 'react'
import { useNavigate } from 'react-router-dom'
import { service_section31, service_section32, service_section33, service_section34, service_section35 } from '../../folder/constants/Appconstants'

function IndustrialWareHouse() {
  const navigate = useNavigate()

  const datas = [
          {
            img:service_section31,
            heading:'Lease and Rent',
            description:"Explore unparalleled lease and rental solutions for industrial warehousing with Fidleitus Corp. Our tailored services streamline the process, offering flexible terms, strategic locations, and efficient logistics support to meet your business needs. Trust us to provide optimal industrial warehousing solutions that drive efficiency and growth for your operations."
          },
          {
            img:service_section32,
            heading:'Built to suit development',
            description:"Built to suit Warehouses for rent and industrial set ups tailored to fit your business requirements; a Built to Suit warehouse is specifically designed to ensure you are able to maximise the functional space. We assist you in expanding your new facility."
          },
          {
            img:service_section33,
            heading:'Structuring industrial parks',
            description:"We specialize in optimizing industrial park layouts, infrastructure, and amenities to create dynamic environments tailored for warehousing and logistics operations. Trust us to design industrial parks that maximize efficiency, productivity, and tenant satisfaction, driving success for your industrial ventures. "
          },
          {
            img:service_section34,
            heading:'Structuring disposal and acquisition of land',
            description:"Our expert team specializes in structuring seamless transactions, leveraging market insights and strategic partnerships to ensure optimal outcomes for sellers and buyers alike. Trust us to facilitate smooth and efficient deals, unlocking value and opportunities in the industrial real estate landscape."
          },
          {
            img:service_section35,
            heading:'Logistics space advisory services',
            description:"Specialized in land optimization for industrial warehousing, we provide expert guidance on site selection, development, and logistics infrastructure, ensuring efficient operations and maximum ROI. Trust us to navigate the complexities of logistics space, unlocking strategic opportunities for your warehousing needs."
          }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-industrial-warehouse-banner_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>INDUSTRIAL WAREHOUSING </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Industrial Warehousing </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
         </div>
        
        
         <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
            {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default IndustrialWareHouse