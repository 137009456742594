import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/primeco_union/Primco-Space-1.jpeg'
import banner2 from '../../folder/images/exclusive/primeco_union/Primco-Space-2.jpeg'
import banner3 from '../../folder/images/exclusive/primeco_union/Primco-Space-3.jpeg'
import banner4 from '../../folder/images/exclusive/primeco_union/PRIMECO-UNION-CITY-1.jpeg'
import banner5 from '../../folder/images/exclusive/primeco_union/PRIMECO-UNION-CITY-2.jpeg'

import ground from '../../folder/images/exclusive/kamadhenu/Ground.jpg'
import location_1 from '../../folder/images/exclusive/primeco_union/map.png'
import lower_basement_floor from '../../folder/images/exclusive/primeco_union/Picture26.jpg'
import upper_basement_floor from '../../folder/images/exclusive/primeco_union/Picture27.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function PrimeCoUnion() {

  const bannerArr = [banner1,banner2,banner3,banner4,banner5]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>PRIMECO UNION CITY</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>Whitefield , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

            <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Overview</h6>
        <h6 className='font_normal text-[13px] my-10'>Primeco Union City is located in the heart of Whitefield, which is a major hub for the technology industry and has the country’s first information technology parks. The location has seen a boom in the growth of residential construction, Hospitals, Shopping malls & Educational Institutes .Strategically Located On Whitefield Main Road, With 3 flexible Entry & Exit Points Primeco Union city offers excellent connectivity from all parts of Bangalore. The project is situated very close to the upcoming Metro station.</h6>
        </div>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>1.65 Million SqFt</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2 Basement + G + 12 Office Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Tower 1-Approx. 37,574 sqft & Tower 2 –Approx 84,359 sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='flex flex-col py-4 border-r border-gray-200 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Floor Plans</h6>
        
        <div className='grid grid-cols-1 pb-10 md:grid-cols-2 w-[90%] md:w-[80%] mx-auto md:gap-5'>
            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>LOWER BASEMENT FLOOR</h6>
                <img src={lower_basement_floor} />
            </div>

            <div className='border border-slate-100 p-2 relative'>
                <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>UPPER BASEMENT FLOOR</h6>
                <img src={upper_basement_floor} />
            </div>
        </div>


        <div className='w-[80%] md:flex items-start  mx-auto '>
            <a href="https://www.google.com/maps/place/12%C2%B059'18.5%22N+77%C2%B043'59.9%22E/@12.9884716,77.7322157,382m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x3bae11f35d0dfc83:0x30cfa512d80115f9!2sWhitefield,+Bengaluru,+Karnataka!3b1!8m2!3d12.9698196!4d77.7499721!3m5!1s0x0:0x0!7e2!8m2!3d12.9884692!4d77.7333103" >
              <img src={location_1} />
            </a>  
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>CBD: 17Kms</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>International Airport: 39 Kms</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>KR Puram : 8Kms</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Outer Ring Road: 11Kms</h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Mr.Sandeep Jayaraj</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845061915 / sandeep.jayaraj@fidelituscorp.com </h6>
                    </div>

                  
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PrimeCoUnion