import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/primeco_towers/500001300568_18124.jpg'
import banner2 from '../../folder/images/exclusive/primeco_towers/857421449_264392.jpg'
import banner3 from '../../folder/images/exclusive/primeco_towers/858106728_225877.jpg'
import banner4 from '../../folder/images/exclusive/primeco_towers/500001600289_18005.jpg'

import typical_floor_plan from '../../folder/images/exclusive/primeco_towers/Picture2-1.png'
import conceptual_floor_plan from '../../folder/images/exclusive/primeco_towers/01.png'


import location_1 from '../../folder/images/exclusive/primeco_towers/image2.png'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube'
import GoBack from '../../folder/components/GoBack'

function PrimecoTower() {

  const bannerArr = [banner1,banner2,banner3,banner4]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>PrimecoTower</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2 uppercase'>Bannerghatta road , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

        <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Overview</h6>
        <h6 className='font_normal text-[13px] my-10'>Primeco Tower is strategically located on Bannerghatta Road with 3 entry and exit points for ease access. Project is suitable for mixed use development (commercial and retail) spread over 6.25 acres of land. This is built to Green Building Standards, boosting Walk to Work concept. Built as per national building code lines with occupation certificate 4 Star, 110 key Business Hotel, providing perfect place to Entertain clients and Host events. Central mall, Cinepolis, Food Court is available in the retail mall spread over 2 Lakh sq.ft.</h6>
        </div>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>9,50,000 sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>3 basement + ground + 12 floors +cafeteria</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>80,270 sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <div className='w-[90%] md:w-[80%] mx-auto my-10'>
       <ReactPlayer width={'100%'} height={'90vh'} style={{width:'100%'}} url='https://www.youtube.com/watch?v=pje8b8b8S2k' />
       </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Floor Plans</h6>
        
        <div className='grid grid-cols-1 pb-10 md:grid-cols-2 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Typical Floor Plan</h6>
            <img src={typical_floor_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Conceptual Floor Plan</h6>
            <img src={conceptual_floor_plan} />
        </div>

       
        </div>


        <div className='w-[80%] md:flex items-start  mx-auto '>
            <a className='md:w-[46%] md:-ml-[2px]' target='_blank' href="https://www.google.com/maps/place/Primeco+Towers,+Arekere/@12.891143,77.5947246,17z/data=!4m5!3m4!1s0x3bae153b298cf939:0xa5d797303713977f!8m2!3d12.8910959!4d77.595937?shorturl=1">
            <img src={location_1} />
            </a>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>NEARBY CATCHMENTS</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>HOSUR ROAD – 1.3 KM</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>MG ROAD – 4.5 KM</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>KEMPEGOWDA AIRPORT – 38.4 KM</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>DAIRY CIRCLE UPCOMING METRO – 1 KM</h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: DEEPA K</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / deepa.k@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PrimecoTower