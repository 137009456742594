import React,{useState} from 'react'


import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import banner1 from '../../folder/images/exclusive/trifecta_adatto/Trifecta-Office-Space-for-Rent-1.jpeg'
import banner2 from '../../folder/images/exclusive/trifecta_adatto/Picture9.jpg'

import floor_plan14 from '../../folder/images/exclusive/trifecta_adatto/Picture14.png'
import floor_plan15 from '../../folder/images/exclusive/trifecta_adatto/Picture15.png'
import floor_plan16 from '../../folder/images/exclusive/trifecta_adatto/Picture16.png'



import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack';

function TrifectaAdatto() {

  const bannerArr = [banner1,banner2]  

  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e] uppercase'>TRIFECTA ADATTO</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] uppercase mt-2'>Mahadevpura , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


            <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Overview</h6> 
            <h6 className='font_normal text-[13px] mt-[5px] w-[90%] text-center'>Landmark building on ITPL main road, very good clientele in the building. G+13 floors with good car parking ratio in the building.</h6>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2,55,411 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2 basement + Ground + 14 Floors</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>25,634 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className=' flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for fit out </h6>
            </div>
           

        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Floor Plans</h6>

        
        <div className='grid grid-cols-1 pb-10 md:grid-cols-3 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>First & Second Floor</h6>
            <img src={floor_plan14} />
        </div>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Third & Fourth Floor</h6>
            <img src={floor_plan15} />
        </div>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Fifth & Sixth Floor</h6>
            <img src={floor_plan16} />
        </div>
        </div>




        {/* <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Site Plan</h6>

        <img src={SitePlan} /> */}
        
       

        
        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Location</h6>

        <div className='w-[80%] md:flex items-start mt-10 mx-auto '>
            <div className='md:w-[50%]  h-[400px]'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124404.98218946984!2d77.699283!3d12.99386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11755807bd47%3A0x9ee8589c830d754e!2s91springboard%20ITPL%20Main%20Road%2C%20Mahadevapura!5e0!3m2!1sen!2sin!4v1720678410094!5m2!1sen!2sin"  className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
              
                 <div>
                    <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>Outer Ring Road:</span> 4 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>CBD:</span> 10.6 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>International Airport:</span> 32.7 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>Whitefield:</span> 7.2 Kms</h6>

                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Mr. Shreeshail M Kotagi</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048470 / shreeshail.kotagi@fidelituscorp.com  </h6>
                    </div>

                   
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default TrifectaAdatto