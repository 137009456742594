import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/prime_land_option/Prime-Land-FCPL-1.jpg'
import banner2 from '../../folder/images/exclusive/prime_land_option/Prime-Land-FCPL-2.jpg'
import banner3 from '../../folder/images/exclusive/prime_land_option/Prime-land-FCPL.jpg'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack';

function PrimeLandOption() {

  const bannerArr = [banner1,banner2,banner3]  

  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>PRIME LAND OPTION</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>JNANA BHARATHI, MYSORE ROAD, BANGALORE</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>
        
        <div className='w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Location Brief</h6>
        <ul className='ml-4 mt-2 list-disc '>
           <li className='font_normal text-[13px] mb-1'>Mysore Road is the main highway linking the southwest part of Bengaluru with Mysore, Mandya, and Ramanagara.</li>
           <li className='font_normal text-[13px] mb-1'>Kengeri Satelite Area, Kengeri, Nayandahalli, and Rajarajareshwari Nagar are some of the most popular localities of the roadway.</li>
           <li className='font_normal text-[13px] mb-1'>Some of the major roads connecting the Mysore Road with the key locations such As Peenya, Yeshwanthpur, and Hebbal are Nice Ring road and Outer Ring Road</li>
           <li className='font_normal text-[13px] mb-1'>From Mysore Road, one can travel through the Outer Ring Road to reach Kempegowda International airport, which is 47 km away.</li>
           <li className='font_normal text-[13px] mb-1'>Provident Housing, Meda Group, Gopalan Enterprises, and Brigade Enterprises are the prominent residential projects on this road.</li>
        </ul>
        </div>

        <div className='grid grid-cols-1 pb-10 md:grid-cols-3 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>LOCATION MAP</h6>
            <img src={banner1} />
        </div>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>CONNECTIVITY</h6>
            <img src={banner2} />
        </div>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>ROAD CONNECTIVITY</h6>
            <img src={banner3} />
        </div>
        </div>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total  area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>1,17,612 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Location</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Jnana Bharathi, Mysore Road</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Ownership</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Single Ownership</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Usage of Land</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Residential</h6>
            </div>
            <div className=' flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Landmark</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Opp TATA 1 mg Warehouse</h6>
            </div>
           

        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>PUBLIC & SOCIAL INFRASTRUCTURE</h6>

        <div className='w-[80%] border-l border-b border-t border-gray-200 mx-auto grid md:grid-cols-4'>
          <div className='border-r border-b md:border-b-0 border-gray-200 p-2'>
             <h6 className='font_bold underline text-[13px]'>HOSPITALS</h6>
             <ul className='ml-4 mt-2 list-disc '>
                <li className='font_normal text-[13px] mb-1'>HK HOSPITAL</li>
                <li className='font_normal text-[13px] mb-1'>SAI SPORTS MEDICINE CENTER</li>
                <li className='font_normal text-[13px] mb-1'>UNITY HOSPITAL</li>
                <li className='font_normal text-[13px] mb-1'>SS SPARSH HOSPITAL</li>
             </ul>
          </div>

          <div className='border-r border-b md:border-b-0 border-gray-200 p-2'>
             <h6 className='font_bold underline text-[13px]'>SHOPPING MALLS</h6>
             <ul className='ml-4 mt-2 list-disc '>
                <li className='font_normal text-[13px] mb-1'>GOPALAN ARCADE MALL</li>
                <li className='font_normal text-[13px] mb-1'>GLOBAL MALLS</li>
                <li className='font_normal text-[13px] mb-1'>G CORP LOTUS MALL</li>
                <li className='font_normal text-[13px] mb-1'>LIRA SHOPPING COMPLEX</li>
             </ul>
          </div>

          <div className='border-r border-b md:border-b-0 border-gray-200 p-2'>
             <h6 className='font_bold underline text-[13px]'>SCHOOLS & COLLEGES</h6>
             <ul className='ml-4 mt-2 list-disc '>
                <li className='font_normal text-[13px] mb-1'>RV ENGINEERING COLLEGE</li>
                <li className='font_normal text-[13px] mb-1'>PES UNIVERSITY</li>
                <li className='font_normal text-[13px] mb-1'>ORCHID INTERNATIONAL SCHOOLS</li>
                <li className='font_normal text-[13px] mb-1'>DON BOSCO TECHNOLOGY INSTITUTE</li>
                <li className='font_normal text-[13px] mb-1'>GLOBAL ACADEMY TECHNOLOGY</li>
             </ul>
          </div>

          <div className='border-r border-gray-200 p-2'>
             <h6 className='font_bold underline text-[13px]'> HOTELS & POPULAR FOOD STREETS</h6>
             <ul className='ml-4 mt-2 list-disc '>
                <li className='font_normal text-[13px] mb-1'>STONNY BROOK</li>
                <li className='font_normal text-[13px] mb-1'>ROYAL AIRAVATHA RESIDENCY HOTEL</li>
                <li className='font_normal text-[13px] mb-1'>TREEBO TREND HOTELFAB EXPRESS</li>
                <li className='font_normal text-[13px] mb-1'>FAB EXPRESS ORIENTAL SUITES</li>
             </ul>
          </div>
           
        </div>


       
     

        <div className='w-[80%] md:flex items-start mt-10 mx-auto '>
            {/* <img src={''} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d497743.0599129448!2d77.507!3d12.932278!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU1JzU2LjIiTiA3N8KwMzAnMjUuMiJF!5e0!3m2!1sen!2sus!4v1720676811528!5m2!1sen!2sus" className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
              
                 <div>
                    <h6 className='text-[13px] font_bold'>PROPERTY ADVANTAGES</h6>
                    <h6 className='text-[12px] font_normal'><span>METRO: </span>Near Jnanabharathi metro station (550 meters)</h6>
                    <h6 className='text-[12px] font_normal'><span>BUS: </span>Near Bengaluru university gate bus stop (500 meters)</h6>
                    <h6 className='text-[12px] font_normal'>Great connectivity with 100 Feet Road for access to the property</h6>
                    <h6 className='text-[12px] font_normal'>It is well-connected to Nice Ring road and Outer Ring Road</h6>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2 border-b mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Fidelitus Corp</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / info@fidelituscorp.com  </h6>
                    </div>

                   
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PrimeLandOption