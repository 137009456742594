import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-1-1.jpeg'
import banner2 from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-1-2.jpeg'
import banner3 from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-1.jpg'


import master_plan from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-Master-Plan.jpg'
import building_section from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-Building-Sections.jpg'
import floor_plan from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-Floor-Plan.jpg'
import lab_zone from '../../folder/images/exclusive/bengaluru_science_park/Bengaluru-Lifescience-Park-LAB-ZONE.png'

import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function BangloreLifeSciencePark() {

  const bannerArr = [banner1,banner2,banner3]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>BENGALURU LIFE SCIENCE PARK</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2 uppercase'>Electronic City Phase-1 , Bengaluru,</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

      


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>9,13,940 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2 Basement + Ground +14 Floors + Terrace</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>62,928  Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>PROPERTY HIGHLIGHTS</h6>

        <div className='md:flex w-[80%] mx-auto'>

          <img src={banner3} className='md:w-[50%]' />

          <div className='md:w-[50%] md:ml-10'>
            <ul className='md:ml-10 mt-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'>A TAPESTRY OF DISTINCT EXPERIENCES</li>
              <li className='font_normal text-[13px] mb-1'>AN URBAN OASIS</li>
              <li className='font_normal text-[13px] mb-1'>CREATING THE FUTURE</li>
              <li className='font_normal text-[13px] mb-1'>CREATING A SENSE OF PLACE AND COMMUNITY</li>
              <li className='font_normal text-[13px] mb-1'>A WORK PLACE WHICH FOCUS ON WELLBEING</li>
              <li className='font_normal text-[13px] mb-1'>TENANCY</li>
              <li className='font_normal text-[13px] mb-1'>ENTRANCE LOBBY</li>
              <li className='font_normal text-[13px] mb-1'>APPROX. 9,13,000 SQ FT OF LEASABLE AREA</li>
              <li className='font_normal text-[13px] mb-1'>LEED GOLD CERTIFICATION – PRE-CERTIFIED</li>
              <li className='font_normal text-[13px] mb-1'>TARGETING WELL GOLD CERTIFICATION</li>
              <li className='font_normal text-[13px] mb-1'>GRADE A COMMERCIAL OFFICE SPACE</li>
              <li className='font_normal text-[13px] mb-1'>62,900 SF TYPICAL FLOOR PLATE</li>
            </ul>
          </div>
        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>LAB ZONE</h6>

        <img src={lab_zone} className='w-[80%] md:w-[60%] mx-auto' />




        <div className='grid gap-5 grid-cols-1 py-10 md:grid-cols-2  w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>MASTER PLAN</h6>
            <img src={master_plan} className='h-[300px] w-[100%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>BUILDING SECTIONS</h6>
            <img src={building_section} className='h-[300px] w-[100%] object-cover'/>
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>FLOOR PLAN</h6>
            <img src={floor_plan} className='h-[300px] w-[100%] object-cover' />
        </div>

        <div className='p-2 relative'>
            <ul className='ml-10 list-disc '>
              <li className='font_normal text-[14px] mb-1'>Floor to Ceiling Glass</li>
              <li className='font_normal text-[14px] mb-1'>Open Floor Plan  Premium Elevator Service</li>
              <li className='font_normal text-[14px] mb-1'>Close Proximity to Toilet  Rooms</li>
              <li className='font_normal text-[14px] mb-1'>Floor Plate Size 62,900 sq ft</li>
            </ul>      
        </div>

        
        </div>


      

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>LOCATION</h6>

        <div className='w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={location_1} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26142.050959025204!2d77.34561401051008!3d12.953637192673742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6d539d61cc6f%3A0xa8b95a801c110969!2sLABZONE%20BENGALURU%20LIFE%20SCIENCES%20PARK!5e0!3m2!1sen!2sus!4v1720677465725!5m2!1sen!2sus"  className='w-full h-full'    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>MG Road 20.1 kms.</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Hosur Road 2.4 mtrs.</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Outer Ring Road 11.5 kms.</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Infosys Campus 2.4 kms.</h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: DEEPA K</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / deepa.k@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default BangloreLifeSciencePark