// 'use client'

import React from 'react'

// import Image from 'next/image'
import Typist from 'react-typist';


function Services() {
  return (
    <div className="w-full h-full relative z-0">

       <div  className="relative overflow-hidden flex  z-0 h-[260px]  md:h-[400px] bg-services_bg bg-no-repeat bg-cover bg-center">
        
        <div className="">
           <div className="absolute left-5 md:left-[5%] top-5 md:top-24 w-[65%]  md:w-[40%]  border-l-4 border-l-[#4190ac] pl-3">
             {/* <Typist stdTypingDelay={15} avgTypingDelay={15} > */}
             <h1 className="text-[21px]  font-[700] font_bold  z-100 !important text-[#e58c3d] ">Our Services</h1>
             <h6 className="text-[14px] w-[70%] md:text-[15px] text-[#fff] font_normal ">See the services listed below for the services we provide. </h6>
             {/* </Typist> */}
           </div> 
         </div> 
 
        
       </div>
 

    </div>
  )
}

export default Services