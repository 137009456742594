import React,{useState} from 'react'


import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import banner1 from '../../folder/images/exclusive/built_to_suit/WhatsApp-Image-2021-05-28-at-11.28.55-AM-1.jpeg'


import location1 from '../../folder/images/exclusive/built_to_suit/WhatsApp-Image-2021-05-28-at-11.29.34-AM.jpeg'
import video from '../../folder/images/exclusive/built_to_suit/ELECTRONIC-CITY-LAND_1.mp4'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack';

function BuiltToSuit() {

  const bannerArr = [banner1,]  

  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e] uppercase'>BUILT TO SUIT</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] uppercase mt-2'>ELCITA KIADB at Electronic City phase 2</h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Overview</h6>
        <h6 className='font_bold w-[90%] md:w-[80%] text-center mx-auto text-[13px] my-10'>Fidelitus corp is exclusively marketing <span className='font_bold'>ELCITA KIADB</span> land for Built-to-Suit at Electronic city Phase II, Bengaluru.</h6>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Location</h6>
        <div className='w-[90%] md:w-[80%] md:flex mx-auto'>
          <img src={location1} className='md:w-[49%] mr-[1%]' />
          <div className='md:w-[49%] ml-[1%] h-[370px]'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d497904.38300706877!2d77.706289!3d12.851156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6cf2ec4e84f9%3A0x786cf35a4e747fd9!2sPhase%202%2C%20Electronic%20City%2C%20Bengaluru%2C%20Karnataka%2C%20India!5e0!3m2!1sen!2sus!4v1720678136473!5m2!1sen!2sus"  className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-3 border-l border-r border-t border-b w-[90%]  md:w-[50%] mx-auto mb-10'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total land Area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>6 Acres</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Approval</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>KIADB</h6>
            </div>
            <div className=' flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Built to suit</h6>
            </div>
           

        </div>




      



        <video className='w-[90%] md:w-[80%] mx-auto' src={video} controls>

        </video>

        

        <div className='w-[80%] md:flex items-start justify-center mt-10 mx-auto '>
            <div >
              
                 <div>
                   
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2  border-b mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Mr. Pramod G P</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845059916 / pramod.gp@fidelituscorp.com  </h6>
                    </div>
                    <div className='pb-2  border-b mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Mr. Shreeshail M Kotagi</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048470 / shreeshail.kotagi@fidelituscorp.com  </h6>
                    </div>

                   
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default BuiltToSuit