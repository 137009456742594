import React,{useState} from 'react'


import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import banner1 from '../../folder/images/exclusive/industrial_land/industrial_banner.png'


import SitePlan from '../../folder/images/exclusive/industrial_land/site-plan.png'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack';

function IndustrialLand() {

  const bannerArr = [banner1,]  

  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e] uppercase'>Industrial Land</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] uppercase mt-2'>Hoskote , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>1,53,824 sq ft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>1 to 4 BHK</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Area in sq ft</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>20,400 to 37,850 sq ft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Industrial</h6>
            </div>
            <div className=' flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>For Rent</h6>
            </div>
           

        </div>




        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Site Plan</h6>
 
        <div className='flex items-center justify-center'>
           <img src={SitePlan}  />
        </div>

        

        <div className='w-[80%] md:flex items-start mt-10 mx-auto '>
            <div className='md:w-[50%]  h-[400px]'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62183.62964927261!2d77.796821!3d13.068937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0578b64bda1b%3A0x1724b6485a5f2f88!2sHoskote%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1720678470634!5m2!1sen!2sin"  className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
              
                 <div>
                    <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>Old Madras Road:</span> 17 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>International Airport:</span> 35 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>Bangalore City Centert:</span> 40 Kms</h6>
                    <h6 className='font_normal text-[12.5px] mb-[5px]'><span className='font_bold'>Whitefield:</span> 25 Kms</h6>

                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: Fidelitus Corp</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / info@fidelituscorp.com  </h6>
                    </div>

                   
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default IndustrialLand