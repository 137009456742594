import React from 'react'
import { fms_website_url, otherServiceArr } from '../../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'

function FMSService() {
  const navigate = useNavigate()

  return (
    <div className='w-[100%]'>
       <div className='relative flex items-center justify-center bg-fms_banner_bg bg-top bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>Facility Management Service </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / FMS </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 w-[80%] mx-auto'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
         </div>
        
        <div className='w-[90%] md:w-[80%] mx-auto py-[5%]'>
        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Facility Management Services</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>At Fidelitus corp we provide expert advice to landlords, tenants, investors for commercial and residential facility management, the service is cost-effective for your benefit.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Fumigation services</h6>
        <h6 data-aos="fade-left"  className='font_normal  text-[13px] mb-6'>With our expertise in fumigation and sterilization, we use a breakthrough DeCo3 formula which is extremely powerful and proven against viruses, bacteria, etc. Workplaces can combat covid 19 with ease with our services.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px] text-[13px]'>We offer -</h6>
        <ul data-aos="fade-left" className='ml-2 mb-5 text-[13px] font_normal list-disc'>
          <li>Fumigation services</li>
          <li>Disinfection tunnel</li>
        </ul>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Security and Escorting services</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>The industry needs more quality and assured security in this age and date. Our exemplary staff is trained to suit all the security and safety needs.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px] text-[13px]'>We provide -</h6>
        <ul data-aos="fade-left" className='ml-2 mb-5 text-[13px] font_normal list-disc'>
          <li>Enhanced safety for women</li>
          <li>Security/safety audit</li>
          <li>Round the clock monitoring</li>
          <li>Equipped for fire hazards and health emergencies</li>
          <li>Exceptional management for front office</li>
          <li>Good communication skills</li>
        </ul>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Hard services</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>Expert, highly qualified, and skilled workforce for all your technical requirements.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px] text-[13px]'>We provide -</h6>
        <ul data-aos="fade-left" className='ml-2 mb-5 text-[13px] font_normal list-disc'>
          <li>Power distribution systems</li>
          <li>Energy management</li>
          <li>Diesel generators</li>
          <li>HVAC systems</li>
          <li>Transformers</li>
          <li>Plumbing</li>
          <li>Sewage treatment and water treatment plants</li>
          <li>Pumps and pumping system</li>
          <li>Thermography</li>
          <li>Vibration analysis</li>
          <li>Safety gap audit</li>
          <li>Electrical audit</li>
        </ul>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Soft and environmental services</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>We provide well-trained resources to manage housekeeping and pest control activities.</h6>


        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Creche management</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>We are in association with feather touch creche, rated as the best play school in Bengaluru, run by qualified professionals and the timings are a perk for working parents.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Fire & Safety training and audit</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>Equipped with a fire protection plan, fire preventive, exposure, and safe assembly area. Good with managing rated approved fire extinguishers to combat small accidental fires Preventive maintenance of firefighting equipment.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[15px]'>Indoor air and environment quality, consulting and audit</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[13px] mb-6'>Source of indoor air pollution, aerodynamics, thermal comfort, and aerobiology consulting. Robotic duct cleaning and HVAC system overhaul Bio cleaning method 100% ecologic, infection prevention and control We have tie ups with waste management agencies that provide scrap clearance, wet garbage, hospital waste, wood, thermo cool etc.</h6>


        <div className='grid grid-cols-2 mt-10 gap-4 md:grid-cols-4'> 

        {otherServiceArr?.map((o)=>(
            <div data-aos="fade-bottom" data-aos-delay="300" className='p-1 md:p-5 shadow mt-2'>
                <img src={o?.image} className='w-[100%] h-[60px] object-contain' />
                <h6 className='font_bold text-center text-[15px] mt-2'>{o?.heading}</h6>
                <h6 className='text-[13px] text-center'>{o?.description}</h6>
            </div>    
        ))}

        </div>   

        <h6 data-aos="fade-bottom" className='text-[13px] mt-10 font_normal'>If you would like to get more information about the Fidelitus Facility Management visit us <a href={fms_website_url} target='_blank' className='underline cursor-pointer font_bold  text-[#027d9b]'>www.fidelitusfacilitymanagement.com</a></h6>

      
        </div>

    </div>
  )
}

export default FMSService