import React from 'react'
import { hrlabs_website_url, otherService1Arr } from '../../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'

function HRManagement() {
  const navigate = useNavigate()
  return (
    <div className='w-[100%]'>
       <div className='relative flex items-center justify-center bg-hr_banner_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10 absolute w-full h-full flex flex-col  items-center justify-center'>
              <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>HR Labs</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / HR Labs </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal w-[80%] mx-auto opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
         </div>
        
        <div className='w-[90%] md:w-[80%] mx-auto py-[5%]'>
        <h6 data-aos="fade-right" className='font_bold text-[20px]'>HR Labs</h6>
        {/* <h6 data-aos="fade-left" className='font_normal  text-[14px] mb-4'>We believe in delivering excellence when it comes to candidates and our services. Fidelitus Corp is a leading recruitment process outsourcing and consulting organization. With a team of world-class professionals, our vision is to help you recruit the best candidates in your industry. A database of the highly-skilled and semi-skilled workforce is ready to work for you and fill up your vacancies.</h6> */}
        <h6 data-aos="fade-left" className='font_normal  text-[13px] my-4'>We provide more than recruitment services. Our services are catered to provide diverse HR solutions, for any challenge in your organization. Below are the services we offer that encompass a variety of end to end HR Solutions. </h6>


        <div className='grid grid-cols-2 mt-10 gap-4 md:grid-cols-4'> 

{otherService1Arr?.map((o)=>(
    <div data-aos="fade-bottom" data-aos-delay="300" className='p-2 md:p-5 shadow mt-2'>
         <img src={o?.image} className='w-[100%] h-[60px] object-contain' />
         <h6 className='font_bold text-center text-[15px] mt-2'>{o?.heading}</h6>
         <h6 className='text-[13px] text-center'>{o?.description}</h6>
    </div>    
))}

</div> 
      
        <h6 data-aos="fade-bottom" className='text-[13px] mt-10 font_normal'>If you would like to get more information about the Fidelitus HR Labs visit us <a href={hrlabs_website_url} target='_blank' className='underline cursor-pointer font_bold text-[#027d9b]'>www.fidelitushrlabs.com</a></h6>

        </div>

    </div>
  )
}

export default HRManagement