import React from 'react'
import { appLogo } from '../folder/constants/Appconstants'

function Splash() {
  return (
    <div className='flex flex-col h-screen items-center justify-center'>


       

        <img src={appLogo} className='w-[100%] h-[100px] md:h-[150px] object-contain' />
        {/* <h6 className='font_thicker text-[#027d9b] text-[17px] mb-2'>Fidelitus <span className='text-[#e67816]'>Projects</span></h6> */}
        {/* <h6 className='font_normal text-[14px]'> Where Dimension takes place</h6> */}

    </div>
  )
}

export default Splash