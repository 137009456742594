import React,{useState} from 'react'

import section1 from '../../folder/images/exclusive/land_for_sale/Land-For-Sale-e1645081021856.jpeg'
import section2 from '../../folder/images/exclusive/land_for_sale/WhatsApp-Image-2022-02-10-at-10.00.01-AM-1.jpeg'
import section3 from '../../folder/images/exclusive/land_for_sale/WhatsApp-Image-2022-02-10-at-10.00.39-AM.jpeg'

import picture2 from '../../folder/images/exclusive/land_for_sale/Picture2.png'
import ReactPlayer from 'react-player/youtube'
import GoBack from '../../folder/components/GoBack'



function LandForSale() {


  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-center text-[#f6821e]'>LAND FOR – SALE / JV/ OTHER DEVELOPMENT OPPORTUNITIES​</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>NEAR MANGALORE INTERNATIONAL AIRPORT</h6>
         
       
       <div className='w-screen mx-auto my-10'>
       <ReactPlayer width={'100%'} height={'90vh'} style={{width:'100%'}} url='https://www.youtube.com/watch?v=5fBCjNJeYX0&t=1s' />
       </div>
       

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Overview</h6>
        <h6 className='font_normal text-[13px] w-[90%] md:w-[80%] mx-auto text-center my-4'>70 acres of land is available with connecting roads being ready for use. The Investor can potentially develop the complete commercial property to earn attractive return on investment.</h6>
        
        <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <ul className='ml-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'>Opportunity – Outright Sale/ JV Development / Development as per master plan.</li>
              <li className='font_normal text-[13px] mb-1'>Total Land Area for Sale – 70 Acres </li>
              <li className='font_normal text-[13px] mb-1'>Location – Mangalore </li>
              <li className='font_normal text-[13px] mb-1'>Landmark – Near Mangalore International Airport</li>
              <li className='font_normal text-[13px] mb-1'>Surrounding – Airport, School, Hospital, Railway Station, Bus Stand, Mall, College, etc. </li>
              <li className='font_normal text-[13px] mb-1'>Usage of Land – Commercial </li>
              <li className='font_normal text-[13px] mb-1'>Current status – Open Land </li>
              <li className='font_normal text-[13px] mb-1'>Sale Price –  To be Discussed </li>
              <li className='font_normal text-[13px] mb-1'>Compliances for Project – Can be obtained very quickly  </li>
              <li className='font_normal text-[13px] mb-1'>Distance from Bangalore – 350 Km </li>
          </ul>

          <div className='grid w-[100%]  md:w-[80%] my-10 mx-auto md:grid-cols-3 gap-5'>
            <img className='w-[100%] h-[200px] object-cover' src={section1} />
            <img className='w-[100%] h-[200px] object-cover' src={section2} />
            <img className='w-[100%] h-[200px] object-cover' src={section3} />

          </div>

        <h6 className='font_normal text-[13px] my-4'>The land holds very good potential to use for mixed-use development. It can be used to create entertainment space, commercial space, and industrial / warehouse & logistics.</h6>

        <h6 className='font_normal text-[13px] text-[#000] mt-10'>The land can either be:</h6>
        <ul className='ml-10 list-disc mt-4'>
              <li className='font_normal text-[13px] mb-1'>Bought outright</li>
              <li className='font_normal text-[13px] mb-1'>Developed jointly with other entities </li>
              <li className='font_normal text-[13px] mb-1'>Developed according to the master plan shown below.  </li>
        </ul>

       
       <div className='md:flex mt-10'>
        <img src={picture2} className='md:w-[50%]' />

        <div>
        <ul className='ml-10 list-decimal  mt-10 md:mt-0'>
              <li className='font_normal text-[13px] mb-1'>MIC Business Point</li>
              <li className='font_normal text-[13px] mb-1'>Incubation Center </li>
              <li className='font_normal text-[13px] mb-1'>IT Offices</li>
              <li className='font_normal text-[13px] mb-1'>Apartments</li>
              <li className='font_normal text-[13px] mb-1'>Service Apartment</li>
              <li className='font_normal text-[13px] mb-1'>School</li>
              <li className='font_normal text-[13px] mb-1'>Commercial Centre</li>
              <li className='font_normal text-[13px] mb-1'>Club house</li>
              <li className='font_normal text-[13px] mb-1'>Villas (Existing)</li>
              <li className='font_normal text-[13px] mb-1'>Villas (Proposed)</li>
              <li className='font_normal text-[13px] mb-1'>Duplex Apartment</li>
              <li className='font_normal text-[13px] mb-1'>Neighborhood Shops</li>
        </ul>
        </div>   
        </div>



        <h6 className='font_bold text-[13px] text-[#4190ac] mt-10'>Recommended Product Mix:</h6>
        <h6 className='font_normal text-[13px] mb-5'>Positioned as an integrated development with the following facilities:</h6>
        <ul className='ml-10 list-disc '>
              <li className='font_normal text-[13px] mb-1'>IT/ITES SEZ- Major economic generator in Mangalore</li>
              <li className='font_normal text-[13px] mb-1'>Financial hubs within the development- banking on the already existing financial sector in the city </li>
              <li className='font_normal text-[13px] mb-1'>Knowledge-based R&D and education hub within the development- providing opportunities to the excellent talent pool in and around the city.  </li>
        </ul>


       

       
        </div>

        <div className='w-[80%] md:flex mt-10 items-start  mx-auto '>
            <div className='md:w-[50%]  h-[400px]'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7778.095163261083!2d74.878958!3d12.904662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba359f96937a9b3%3A0x189c177babb8be95!2sMangalore%20Hills%20site%20office!5e0!3m2!1sen!2sin!4v1720678308160!5m2!1sen!2sin"  className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className='md:w-[50%] mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>IDEAL LOCATION</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Spread across sprawling 70 acres of green carpet, is one of its kind with the distinct advantage of being strategically located between Mangalore’s central business district and the Mangalore airport</h6>
                 <h6 className='font_bold underline my-2 text-[12.5px] mb-[5px]'>PROXIMITY</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Airport – 7.5 km (14 min )</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Railway Station – 8 km (18 min ) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>KSRTC Bus Station – 6.4 km (13 min) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Beach – 10 km (20 min) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>School – 1.5 km (5 min) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>City Centre Mall – 7.8 km (17 min ) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Hospital – 5.9 km (13 min) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Golf club – 6.6 km (13 min) </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Amusement Park – 7 km (15 min) </h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className='pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: RamDas Rai</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845046730 / ramdas.rai@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default LandForSale