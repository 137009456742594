import './App.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Toaster } from 'react-hot-toast';
import { LuArrowUpSquare } from "react-icons/lu";
import ScrollToTop from 'react-scroll-up';

import React,{ useState,useEffect } from 'react';
import './App.css';
import Navbar from './folder/components/Navbar';
import Footer from './folder/components/Footer';
import YouTube from 'react-youtube';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Splash from './pages/Splash';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import WhyUs from './pages/WhyUs';
import Joinus from './pages/JoinUs';
import FidelitusGallery from './pages/other_services/FidelitusGallery';
import HRManagement from './pages/other_services/HRManagement';
import RealEstate from './pages/other_services/RealEstate';
import Projects from './pages/other_services/Projects';
import FMSService from './pages/other_services/FMSService';
import Corporaterealestate from './pages/services/Corporaterealestate';
import ResedentialLeasing from './pages/services/ResedentialLeasing';
import LandInvestment from './pages/services/LandInvestment';
import IndustrialWareHouse from './pages/services/IndustrialWareHouse';
import Blogs from './pages/Blogs';
import BlogsDetail from './pages/BlogsDetail';
import ExclusiveProject from './pages/ExclusiveProject';
import Kamadhenu from './pages/exclusive_projects/Kamadhenu';
import TNRDevine from './pages/exclusive_projects/TNRDevine';
import PrimeLand from './pages/exclusive_projects/PrimeLand';
import IndustrialLand from './pages/exclusive_projects/IndustrialLand';
import TrifectaAdatto from './pages/exclusive_projects/TrifectaAdatto';
import LandForSale from './pages/exclusive_projects/LandForSale';
import BuiltToSuit from './pages/exclusive_projects/BuiltToSuit1';
import PrimecoTower from './pages/exclusive_projects/PrimecoTower';
import AjmeraNucleus from './pages/exclusive_projects/AjmeraNucleus';
import OzononeChambers from './pages/exclusive_projects/OzoneChambers';
import QubeSoftwarePark from './pages/exclusive_projects/QubeSoftwarePark';
import BangloreLifeSciencePark from './pages/exclusive_projects/BangloreLifeSciencePark';
import PrimeCoUnion from './pages/exclusive_projects/PrimeCoUnion';
import ShilpaAnanya from './pages/exclusive_projects/ShilpaAnanya';
import PrimeLandOption from './pages/exclusive_projects/PrimeLandOption';
import NMR from './pages/exclusive_projects/NMR';
import GuruKrupa from './pages/exclusive_projects/GuruKrupa';
import NoData from './pages/NoData';
import VillaPlots from './pages/exclusive_projects/VillaPlots';

function App() {

  const [loader,setloader] = useState(false)

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <Navbar />
          <Home /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/home",
      element: (
        <div>
          <Navbar />
          <Home /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/about",
      element: (
        <div>
          <Navbar />
          <About /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/contact",
      element: (
        <div>
          <Navbar />
          <Contact /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/services",
      element: (
        <div>
          <Navbar />
          <Services /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/why-us",
      element: (
        <div>
          <Navbar />
          <WhyUs /> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/join_us",
      element: (
        <div>
          <Navbar />
          <Joinus /> 
          <Footer/>
        </div>
      ),
    },
    // {
    //   path:"/whats-more/real-estate-transaction",
    //   element: <div>
    //   <Navbar />
    //     <RealEstate />
    //   <Footer/>
    // </div>,
    // },
    {
      path:"/whats-more/projects",
      element: <div>
      <Navbar />
      <Projects />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/hr-labs",
      element: <div>
      <Navbar />
        <HRManagement />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/facility-management-services",
      element: <div>
      <Navbar />
        <FMSService />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/fidelitus-gallery",
      element: <div>
      <Navbar />
        <FidelitusGallery />
      <Footer/>
    </div>,
    },
    {
      path:"/services/corporate-real-estate",
      element: <div>
      <Navbar />
        <Corporaterealestate />
      <Footer/>
    </div>,
    },
    {
      path:"/services/resedentail-leasing-rent-sale",
      element: <div>
      <Navbar />
        <ResedentialLeasing />
      <Footer/>
    </div>,
    },
    {
      path:"/services/land-investment",
      element: <div>
      <Navbar />
        <LandInvestment />
      <Footer/>
    </div>,
    },
    {
      path:"/services/industrial-warehousing",
      element: <div>
      <Navbar />
        <IndustrialWareHouse />
      <Footer/>
    </div>,
    },
    {
      path:"/blogs",
      element: <div>
      <Navbar />
        <Blogs />
      <Footer/>
    </div>,
    },
    {
      path:"/blogs/detail/:id?",
      element: <div>
      <Navbar />
        <BlogsDetail />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects",
      element: <div>
      <Navbar />
        <ExclusiveProject />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/dreamcatchers-villa-plots",
      element: <div>
      <Navbar />
        <VillaPlots />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/kamadhenu",
      element: <div>
      <Navbar />
        <Kamadhenu />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/tnr_devine",
      element: <div>
      <Navbar />
        <TNRDevine />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/nmr-green-nest",
      element: <div>
      <Navbar />
        <NMR />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/prime-land-option-for-sale-at-dasanpura",
      element: <div>
      <Navbar />
        <PrimeLand />
      <Footer/>
    </div>,
    },

    {
      path:"/exclusive-projects/prime-land-option",
      element: <div>
      <Navbar />
        <PrimeLandOption />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/shilpa-ananaya-tech-park",
      element: <div>
      <Navbar />
        <ShilpaAnanya />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/gurukrupa-commercial-space-for-rent",
      element: <div>
      <Navbar />
        <GuruKrupa />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/primeco-union-city",
      element: <div>
      <Navbar />
        <PrimeCoUnion />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/bengaluru-lifescience-park",
      element: <div>
      <Navbar />
        <BangloreLifeSciencePark />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/qube-software-park",
      element: <div>
      <Navbar />
        <QubeSoftwarePark />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/ozone-chambers",
      element: <div>
      <Navbar />
        <OzononeChambers />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/ajmera-nucleus",
      element: <div>
      <Navbar />
        <AjmeraNucleus />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/primeco-towers",
      element: <div>
      <Navbar />
        <PrimecoTower />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/built-to-suit",
      element: <div>
      <Navbar />
        <BuiltToSuit />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/land-for-sale",
      element: <div>
      <Navbar />
        <LandForSale />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/industrial-area",
      element: <div>
      <Navbar />
        <IndustrialLand />
      <Footer/>
    </div>,
    },
    {
      path:"/exclusive-projects/trifecta-adatto",
      element: <div>
      <Navbar />
        <TrifectaAdatto />
      <Footer/>
    </div>,
    },
    {
      path:"*",
      element: <div>
      <Navbar />
        <NoData />
      <Footer/>
    </div>,
    },


    
    
  ]);

  useEffect(()=>{
    setloader(true)
    setTimeout(() => {
      setloader(false)
    }, 2000);
    Aos.init();
  },[])


  return (
    <div className='relative w-screen overflow-x-hidden'>
       <Toaster  position="bottom-center" toastOptions={{
          success: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          },
          error: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          }
        }}  />
        {loader ?
        <Splash /> :
        <RouterProvider router={router} />}




       <ScrollToTop showUnder={260}>
            <div className='bg-[#027d9b] group p-[10px] rounded z-50'>
             <LuArrowUpSquare size={20} className='z-30 group-hover:animate-spin text-white opacity-100' />
            </div>
        </ScrollToTop>
      
    </div>
  );
}

export default App;
