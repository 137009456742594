
import React,{useState} from 'react'
import Typist from 'react-typist';
import {AiOutlineUsergroupAdd,AiOutlineCaretDown} from 'react-icons/ai';
import {RiSearchLine} from 'react-icons/ri';
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import {FiUpload} from 'react-icons/fi'
import axios from 'axios';
import { message } from 'antd';
import { department, email, mobile } from '../folder/constants/Appconstants';
import {IoClose} from 'react-icons/io5'
import ErrorComponent from '../folder/constants/ErrorComponent';
import { useNavigate } from 'react-router-dom';

function Joinus() { 
  const navigate = useNavigate()

   
  const [step,setstep] = useState(1) 
  const [data,setdata] = useState({name:'',mobile:'',email:'',experience:'',previous_company:'',query:'',file:''})
  const [error,seterror] = useState({name:'',mobile:'',email:'',experience:'',previous_company:'',query:'',file:''})


  
  // const [departments,setdepartments] = useState(
  //   [
  //     'Fidelitus Transactions','Fidelitus Project','Fidelitus HR Labs','Fidelitus Facility Managment ','Fidelitus Gallery','Fidelitus Digital Media',
  //   ]
  // )

  // const [data,setdata] = useState(
  //   [
  //     {
  //          department : "Fidelitus Gallery",
  //          role :  "Business Development",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-3" ,
  //          open_positon: "2", 
  //          location: "Bengaluru",
  //      },
  
  //      {
  //          department : "Fidelitus Gallery",
  //          role :  "Business Development Manager",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "3-5" ,
  //          open_positon: "1", 
  //          location: "Bengaluru",
  //      },
  
  
  //  {
  //          department : "Fidelitus Gallery",
  //          role :  "Sales Manager",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "0-2" ,
  //          open_positon: "3", 
  //          location: "Bengaluru",
  //      },
  
  
  //  {
  //          department : "Fidelitus Transactions",
  //          role :  "Data Entry",
  //          jd: " Creating development plans and forecasting sales targets and growth projections Identifying market opportunities through meetings, networking and other channels Meeting existing and potential clients and building positive relationships Liaising with colleagues to develop sales and marketing strategies",
  //          experience: "0-1" ,
  //          open_positon: "2", 
  //          location: "Bengaluru",
  //      },
  
  
  //     {
  //          department : "Fidelitus Transactions",
  //          role :  "Business Development",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-2" ,
  //          open_positon: "4", 
  //          location: "Bengaluru",
  //     },
  //  {
  //          department : "Fidelitus Transactions",
  //          role :  "Vice President",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "3-5" ,
  //          open_positon: "2", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus Facility Management",
  //          role :  "Business Development",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-3" ,
  //          open_positon: "3", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus Facility Management",
  //          role :  "Finance Manager",
  //          jd: "Creating development plans and forecasting sales targets and growth projections Identifying market opportunities through meetings, networking and other channelsMeeting existing and potential clients and building positive relationships Liaising with colleagues to develop sales and marketing strategies",
  //          experience: "2-3" ,
  //          open_positon: "1", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus Facility Management",
  //          role :  "Human Resource",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-2" ,
  //          open_positon: "1", 
  //          location: "Bengaluru",
  //      },
  
  
  //    {
  //          department : "Fidelitus HR Labs",
  //          role :  "Business Development",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "2-3" ,
  //          open_positon: "1", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus HR Labs",
  //          role :  "Junior Talent Acquisition Executive",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-3" ,
  //          open_positon: "4", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus HR Labs",
  //          role :  "Manager Talent Acquisition",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "3-5" ,
  //          open_positon: "1", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus Project",
  //          role :  "Business Development",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "2-5" ,
  //          open_positon: "2", 
  //          location: "Bengaluru",
  //      },
  //  {
  //          department : "Fidelitus Project",
  //          role :  "3D Designer",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "0-3" ,
  //          open_positon: "5", 
  //          location: "Bengaluru",
  //      },
  
  //  {
  //          department : "Fidelitus Project",
  //          role :  "MEP Executive",
  //          jd: "<ul><li>Creating development plans and forecasting sales targets and growth projections.</li> <li> Identifying market opportunities through meetings, networking and other channels. </li> <li> Meeting existing and potential clients and building positive relationships. </li> <li>Liaising with colleagues to develop sales and marketing strategies.</li>",
  //          experience: "1-2" ,
  //          open_positon: "2", 
  //          location: "Bengaluru",
  //      },
  
  
  // ]
  
  // )

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  } 

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.mobile && data.mobile.length < 10){
      seterror({...error,mobile:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.experience){
      seterror({...error,experience:'This Field is required *'})
    }else if(!data?.file){
      seterror({...error,file:'This Field is required *'})
    }else{

      const fd = new FormData()
      fd.append('name',data?.name)
      fd.append('mobile',data?.mobile)
      fd.append('email',data?.email)
      fd.append('experience',data?.experience)
      fd.append('previous_company',data?.previous_company)
      fd.append('query',data?.query)
      fd.append('file',data?.file)
      fd.append('department_website',department)

      const headers = {headers:{
        'Content-Type':'multipart/form-data'
      }}
      
      await axios.post(`${process.env.REACT_APP_API_URL}applicant/create`,fd,headers)
      .then((res)=>{
        if(res?.status === 201){
          message.success('Form Submitted Successfully')
          setdata({name:'',mobile:'',email:'',experience:'',previous_company:'',query:'',file:''})
          seterror({name:'',mobile:'',email:'',experience:'',previous_company:'',query:'',file:''})
        }
      }).catch((err)=>{      
      }) 
    }
  }





  const [selected_departments,setselected_departments] = useState('')
  
  return (
    <div className="w-full h-full overflow-hidden">
      {/* <div className='h-[260px] w-screen overflow-hidden relative md:h-[400px] grid place-items-center  bg-joinus_bg bg-no-repeat bg-cover md:bg-cover bg-right-top  '>
            <div className="absolute left-5 md:left-[5%] top-5 md:top-24 w-[60%]  md:w-[40%]  border-l-4 border-l-[#4190ac] pl-3">
              <Typist stdTypingDelay={15} avgTypingDelay={15} >
              <h1 className="text-[21px]  font-[700] font_bold  z-100 !important text-[#e58c3d] ">Careers</h1>
              <h6 className="text-[14px] w-[70%] md:text-[15px] text-[#fff] font_normal ">We are hiring!, If you're intrested in joining martian fidelitus army we are looking a people like you. </h6>
              </Typist>
            </div> 
      </div> */}

      <div className='relative flex items-center justify-center  bg-joinus_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
        <div className='z-10'>
          <h6 className='text-[30px] text-white text-center tracking-[7px] font_thicker opacity-80'>CAREERS </h6>
          <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Careers </h6>
          <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80'>We are hiring!, If you're intrested in joining martian fidelitus army we are looking a people like you. </h6>
        </div>
        <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
        </div> 
      </div>

      <div className='bg-slate-100  mx-auto py-10'>
         <h6 data-aos="fade-left" className="text-center  font-[400] font_bold text-[24px]">Meet  <span className='text-[#4190ac]'>Us</span></h6>

          <div className='grid w-[80%] md:w-[80%]  mt-[2%] mb-[2%] gap-5 mx-auto md:grid-cols-3'>
            <div data-aos="fade-right" className='w-[100%] p-[5%] bg-white shadow flex flex-col items-center justify-center '>
            <IoLocationOutline size={30} className='bg-slate-100 p-2' />
            <h6 className='text-[13px] font_normal mt-2'>Address</h6>
            <h6 className='text-[12px] text-center mt-2 font_bold text-[#027d9b]'>Fidelitus Corp, Brigade Software Park., No. 42, Ground Floor, B Block, 27th Cross,BSK 2nd Stage, Bangalore - 560070, Karnataka</h6>
            </div>

            <div data-aos="fade-up" className='w-[100%] p-[5%] bg-white shadow flex flex-col items-center justify-center '>
            <MdLocalPhone size={30} className='bg-slate-100 p-2' />
            <h6 className='text-[13px] font_normal mt-2'>Phone Number</h6>
            <h6 className='text-[12px] text-center mt-2 font_bold text-[#027d9b]'>{mobile}</h6>
            </div>

            <div data-aos="fade-left" className='w-[100%] p-[5%] bg-white shadow flex flex-col items-center justify-center'>
            <MdOutlineMailOutline size={30} className='bg-slate-100 p-2' />
            <h6 className='text-[13px] font_normal mt-2'>Email Address</h6>
            <h6 className='text-[12px] text-center mt-2 font_bold text-[#027d9b]'>{email}</h6>
            </div>
          </div> 
     </div>

     <div className='w-[80%]  md:flex mx-auto py-[5%]'>

      <div className='md:w-[50%]  md:border-r'>
        <div data-aos="fade-right" className='md:w-[90%]'>
         <h6 className='text-[20px] mt-2 font_bold'>Looking to Join our Family as an Real Estate Expert.</h6>
         <h6 className='text-[14px] font_normal'>Contact us and you'll receive a call as soon as you get shortlisted</h6>
         
         <div className='mt-5 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Name</h6>
                <input type='text' name="name" value={data?.name} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
              </div>

              <ErrorComponent error={error?.name} />

              <div className='mt-2 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Mobile</h6>
                <input type='text' name="mobile" value={data?.mobile} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
              </div>
              <ErrorComponent error={error?.mobile} />


              <div className='mt-2 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Email</h6>
                <input type='text' name="email" value={data?.email} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
              </div>
              <ErrorComponent error={error?.email} />


              <div className='mt-2 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Experience</h6>
                <input min={0} name="experience" value={data?.experience} onChange={handleChange} type='number' className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
              </div>

              <ErrorComponent error={error?.experience} />



              <div className='mt-2 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Previous Company</h6>
                <input type='text'  name="previous_company" value={data?.previous_company} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
              </div>


              <div className='mt-2 w-full'>
                <h6 className='mb-1 text-[13px]'>Upload Resume</h6>
                {data?.file === '' ? 
                <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer rounded   border-dashed border`}>
                  <FiUpload size={24} />
                  <span className='font-bold font_normal text-[10px] mt-1 text-center'>Click To Upload Photo</span>
                  <span className='font-bold font_normal text-[7px] mt-0 text-center'>Supported Type JPEG,PNG,SVG,WEBP</span>
                  <input type='file' onChange={({target:{files}})=>{
                    files[0] && setdata({...data,file:files[0]});seterror({...error,file:''})
                  }} accept="image/*" className='input-field' hidden />
               </form> 
                :
                <div className='flex border relative p-2'>
                    <h6 className='text-[13px] font_normal truncate w-[95%] pl-2'>{data?.file?.name}</h6>
                    <IoClose className='absolute  right-2' onClick={()=>{setdata({...data,file:''});seterror({...error,file:''})}}/>
                </div>  
                }

              </div>

              <ErrorComponent error={error?.file} />


              <div className='mt-2 w-full'>
                <h6 className='mb-1 font_normal text-[13px]'>Query</h6>
                <textarea name="query" value={data?.query} onChange={handleChange} type='text' className='border w-full focus:ring-0 focus:outline-none p-1' />
              </div>


              <button onClick={submitForm} className='text-[12px] flex font_normal mt-2 items-end justify-end bg-black text-white p-2'>
                SUBMIT
              </button>


         </div>
      </div>

      <div data-aos="fade-left" className='md:ml-[4%] md:w-[48%] mt-10'>
        <div className='w-[100%]'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.201402509898!2d77.5725512!3d12.9205498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14293cbbedf9%3A0x4f12794ce5a209a4!2sFidelitus%20Corp!5e0!3m2!1sen!2sin!4v1691664501989!5m2!1sen!2sin" className='w-full' height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>


     </div>
        
        {/* <div data-aos="fade-left" className='flex  z-90 items-center border-b  pb-2 border-gray-100'>

          <h6 onClick={()=>setstep(1)} className={`mx-2 min-h-7 max-h-7 relative cursor-pointer text-[13px] font_normal after:bg-[#4190ac] after:absolute  after:h-[2px] after:w-0 after:-bottom-0.5 after:left-0  hover:after:w-full after:transition-all after:duration-300  border-r-gray-[300]  ${step === 1 && 'border-b-2 border-[#4190ac] '} `}>ALL</h6>
         
          <h6 className='mx-2 border-r  border-r-gray-[100] text-white'>1</h6>
          <div className='group relative min-w-[180px] max-w-[180px]'>
          <h6 className={`ml-4 relative min-h-7 max-h-7 cursor-pointer flex mx-2 text-[13px] items-center uppercase font_normal after:bg-[#4190ac] after:absolute after:h-[2px] after:w-0 after:-bottom-0.5 after:left-0  hover:after:w-full after:transition-all after:duration-300 items-center ${step === 2 && 'border-b-2 border-[#4190ac]'} `}><AiOutlineUsergroupAdd size={20}  className='mr-1'/> departments <AiOutlineCaretDown size={12}  className='ml-4 text-gray-400'/> </h6>
          <div className='hidden group-hover:block bg-white shadow ml-4 w-[180px]'>
           <div className='absolute bg-white shadow  z-50 pt-2 w-full'>
            {departments?.map((d,i)=>(
              <h6 onClick={()=>{setselected_departments(d);setstep(2)}} className={`text-[11px] text-gray-600 cursor-pointer font_normal p-1 px-2 hover:bg-gray-50 ${selected_departments === d && 'bg-gray-50'} `} key={i}>{d}</h6>
            ))}
            </div>
          </div>
          </div>
          <h6 className='mx-2 border-r  border-r-gray-[100] text-white'>1</h6>
          
          <div className='hidden md:flex'>
            <input type='text' className='text-[13px] ml-2 w-[180px] font_normal focus:ring-0 focus:outline-none' placeholder='search' />
            <RiSearchLine className='text-gray-300' />
          </div>

        </div> */}

        {/* <div className='flex md:hidden mt-4 border-b  pb-2 border-gray-100'>
            <input type='text' className='text-[13px] ml-2 w-full font_normal focus:ring-0 focus:outline-none' placeholder='search' />
            <RiSearchLine className='text-gray-300' />
          </div>


        <div className='grid z-50 md:grid-cols-4 mt-10 bg-home_trans_bg bg-no-repeat bg-right-bottom'>

         
         {data?.map((d)=>(
          <div data-aos="fade-up" className='mr-4 my-2 relative p-2 border border-gray-100'>

             <div className='w-[80%]'> 
                <h6 className='font_bold border-l-4 border-l-[#4190ac] pl-2 text-[13px]'>{d?.role}</h6>
                <h6 className='text-[10px] font_normal mt-2'>Check Availability</h6>
                <h6 className='font_normal text-gray-500 text-[11px]'>{d?.open_positon} - Position | Location : {d?.location}</h6>
             </div>

             <h6 className='uppercase text-[11px] absolute top-2 right-2 bg-gray-100 hover:bg-gray-200 cursor-pointer p-2'>View Job</h6>
          </div>
          ))}

        </div> */}

    </div>
  )
}

export default Joinus