import React from 'react'
import { BangloreLifeSciencePark, GuruKrupaBanner, NMRGreenNestBanner, PrimeCoLandOptionBanner, PrimeCoUnionCity, QubeSoftwareParkBanner, ShilpaAnanyaBanner, ajmeraNucleusBanner, builtToSuitBanner, industrialBanner, kamadhenuBanner, landforSaleBanner, ozoneChamberBanner, primeLandOption, primecoTowerBanner, tnrdrivetechBanner, trifectaBanner, villaPlotsBanner } from '../folder/constants/Appconstants'
import { useNavigate } from 'react-router-dom'

function ExclusiveProject() {
  
  const navigate = useNavigate()
  
  return (
    <div>
        <div className='relative flex items-center justify-center bg-exclusive_bg bg-bottom bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
            <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>EXCLUSIVE PROJECTS </h6>
            <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Exclusive Projects </h6>
            <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>INNOVATED | MARKETED | DELIVERED BY <span className='font_bold'>FIDELITUSCORP</span> - EMPOWERING REALESTATE SOLUTIONS</h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
        </div>

         <div className='w-[80%] md:w-[80%] mx-auto py-20'>
          <div className='grid gap-5  md:grid-cols-3'>

          <div data-aos="fade-up" onClick={()=>navigate('dreamcatchers-villa-plots')} className='group cursor-pointer h-[220px] relative overflow-hidden'>
            <img src={villaPlotsBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>VILLA PLOTS</h6>
              <h6 className='text-[11px] text-[#fff]'>DREAMCATCHERS</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>BANNERGHATTA ROAD, BENGALURU </h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Villa Plots starts from 1899 Sq.ft to 2928 Sq.ft</h6>
                      </div>
                    </div>
                 </div> 
            </div>
           
            <div data-aos="fade-up" onClick={()=>navigate('kamadhenu')} className='group cursor-pointer h-[220px] relative overflow-hidden'>
            <img src={kamadhenuBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>KAMADHENU</h6>
              <h6 className='text-[11px] text-[#fff]'>COMMERCIAL OFFICE SPACE FOR RENT</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>ADUGODI , BANGALORE </h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total built-up area - 44,600 Sq.Ft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('tnr_devine')} className='group h-[220px] relative overflow-hidden'>
            <img src={tnrdrivetechBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>TNR DIVINE TECH PARK</h6>
              {/* <h6 className='text-[11px] text-[#fff]'>ELECTRONIC CITY, BANGALORE</h6> */}
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>ELECTRONIC CITY, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Grand Total</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Block A  - 4,14,635.54 Sq.ft </h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Block B   - 4,40,563.25 Sq.ft </h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total Sq.ft  - 8,55,198.79 </h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('nmr-green-nest')} className='group h-[220px] relative overflow-hidden'>
            <img src={NMRGreenNestBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>NMR GREEN NEST</h6>
              {/* <h6 className='text-[11px] text-[#fff]'>ELECTRONIC CITY, BANGALORE</h6> */}
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>KIADB AEROSPACE -HARDWARE PARK, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total built-up area  - 6,23,189.83 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('prime-land-option-for-sale-at-dasanpura')} className='group h-[220px] relative overflow-hidden'>
            <img src={primeLandOption} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>PRIME LAND OPTION FOR SALE PARK</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>DASANPURA, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Land Area: 34,848 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('prime-land-option')} className='group h-[220px] relative overflow-hidden'>
            <img src={PrimeCoLandOptionBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>PRIME LAND OPTION</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>JNANA BHARATHI,</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>MYSORE ROAD, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Land Area :1,17,612 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('shilpa-ananaya-tech-park')} className='group h-[220px] relative overflow-hidden'>
            <img src={ShilpaAnanyaBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>SHILPA ANANYA TECH PARK</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>GREEN BUILDING – GOLD RATED LEED CERTIFIED BUILDING</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>ELECTRONIC CITY PHASE 1, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Land Area : 85,302 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('gurukrupa-commercial-space-for-rent')} className='group h-[220px] relative overflow-hidden'>
            <img src={GuruKrupaBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>GURUKRUPA</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial Office Space for Rent on Hosur Main Road</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial Office Space exclusively marketed by Fidelitus corp on Hosur main road Bangalore.A premier office space cater to Software, ITES, BPO and BT companies is located in Hosur Road, Bangalore.</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('primeco-union-city')} className='group h-[220px] relative overflow-hidden'>
            <img src={PrimeCoUnionCity} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>PRIMECO UNION CITY</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial Office Space at Whitefield</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Primeco union city whitefield  1.65 million SqFt</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('bengaluru-lifescience-park')} className='group h-[220px] relative overflow-hidden'>
            <img src={BangloreLifeSciencePark} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>BENGALURU LIFE SCIENCE PARK</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>World’s Best Biotechnology Infrastructure</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Neeladri Rd, Electronics City Phase 1, Electronic City, Bengaluru</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total Built-up Area : 9,13,940 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('qube-software-park')} className='group h-[220px] relative overflow-hidden'>
            <img src={QubeSoftwareParkBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>QUBE SOFTWARE PARK</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>PRIME COMMERCIAL OFFICE SPACE FOR RENT</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>OUTER RING ROAD, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total Built-up Area : Tower A –3,18,569  Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('ozone-chambers')} className='group h-[220px] relative overflow-hidden'>
            <img src={ozoneChamberBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>OZONE CHAMBERS</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>COMMERCIAL SPACE FOR RENT AND SALE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial space at SILK BOARD JUNCTION, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total Built-up Area : Tower A – 9,21,365 Sqft / Tower B – 7,50,778 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('ajmera-nucleus')} className='group h-[220px] relative overflow-hidden'>
            <img src={ajmeraNucleusBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>AJMERA NUCLEUS</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>COMMERCIAL SPACE FOR RENT</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>COMMERCIAL SPACE FOR RENT, AT ELECTRONIC CITY PHASE II, BANGALORE</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Total Built-up Area : 1,30,000 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('primeco-towers')} className='group h-[220px] relative overflow-hidden'>
            <img src={primecoTowerBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>PRIMECO TOWERS</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial Office Space at Bannerghatta Main Road</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial space at Bannerghatta main road  9,50,000 Sqft</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('built-to-suit')} className='group h-[220px] relative overflow-hidden'>
            <img src={builtToSuitBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>BUILT TO SUIT</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>ELCITA KIADB at Electronic City phase 2</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Fidelitus corp is exclusively marketing ELCITA KIADB land for Built-to-Suit at Electronic city Phase II, Bengaluru.</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('land-for-sale')} className='group h-[220px] relative overflow-hidden'>
            <img src={landforSaleBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>LAND FOR SALE</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>LAND FOR – SALE / JV/ OTHER DEVELOPMENT OPPORTUNITIES</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>NEAR MANGALORE INTERNATIONAL AIRPORT – 70 Acres</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('trifecta-adatto')} className='group h-[220px] relative overflow-hidden'>
            <img src={trifectaBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>TRIFECTA ADATTO</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>Commercial Office Space at Whitefield</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>2,55,411 SqFt</h6>
                      </div>
                    </div>
                 </div> 
            </div>

            <div data-aos="fade-up" onClick={()=>navigate('industrial-area')} className='group h-[220px] relative overflow-hidden'>
            <img src={industrialBanner} className='w-[100%] h-[220px]' />
            <div className='absolute bottom-2 p-1 left-2 bg-[#4190ac]/70'>
              <h6 className='text-[#fff] text-[12.5px] font_bold mt-1'>INDUSTRIAL LAND</h6>
            </div>
            <div className='inline-block bg-[#000]/50 duration-300 transition-all group-hover:-translate-y-[220px] h-[220px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='py-2 px-0 min-h-[220px] '>  
                        <h6 className='font_normal text-[12px] ml-2 '>Industrial Land at Hoskote</h6>
                        <h6 className='font_normal text-[12px] ml-2 '>Rent: 1,53,824 SqFt</h6>
                      </div>
                    </div>
                 </div> 
            </div>


         </div>
         </div>
        {/* <div className='grid grid-cols-2'>

        </div> */}

    </div>
  )
}

export default ExclusiveProject