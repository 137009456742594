import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/gurukrupa/COMMERCIAL-ELEVATION-06112016.jpg'
import banner2 from '../../folder/images/exclusive/gurukrupa/Gurukrupa-Building.png'
import banner3 from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Space.png'
import banner4 from '../../folder/images/exclusive/gurukrupa/IMG_20181219_120735.jpg'



import location_1 from '../../folder/images/exclusive/kamadhenu/Location1.jpg'
import sectional_plan from '../../folder/images/exclusive/kamadhenu/Sectional-Plan.jpg'
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import ground_floor from '../../folder/images/exclusive/gurukrupa/G1.png'
import mezzaine_floor from '../../folder/images/exclusive/gurukrupa/G2.png'
import typical_floor from '../../folder/images/exclusive/gurukrupa/G3.png'

import interior1 from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Interior-Picture-1-768x520.png'
import interior2 from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Interior-Picture-768x528.png'
import interior3 from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Interior-Picture-2.png'

import chart from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Area-Statement.png'

import side1 from '../../folder/images/exclusive/gurukrupa/Gurukrapa-Space.png'
import side2 from '../../folder/images/exclusive/gurukrupa/Gurukrupa-Space.png'
import GoBack from '../../folder/components/GoBack'




function GuruKrupa() {

  const bannerArr = [banner1,banner2,banner3,banner4]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>GuruKrupa</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2'>Hosur Road , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

            <div className='py-10'>
                <h6 className='font_bold text-[15px] text-center text-[#4190ac] mt-10'>Overview</h6>
                
                <div className='w-[90%] md:w-[80%] mx-auto'>
                <h6 className='font_normal  mt-5 text-[13.5px] text-[#000]'>This space is unique blend of IT office space or commercial office space. Grade A Office space with support amenities. Hosur Road is a part of the National Highway 7. It is well-connected to the Outer Ring Road and Sarjapur Road. NICE Road and important areas like BTM Layout are easily accessible. HSR Layout is also well-linked to this area. The Project is on the way to Electronic City and Hi-Tech City.</h6>
                </div>
            </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>40,811 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ground + Mezzanine + 3 floors + Terrace</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>10,048 Sqft</h6>
            </div>
          
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>

        <div className='md:flex w-[90%] justifw-between my-10 md:w-[80%] mx-auto'>

<div className='md:w-[50%]'>
    <h6 className='font_bold  text-[#000] ml-2 mb-2'>PROJECT LOCATION BRIEF</h6>
    <ul className='md:ml-10 mt-10 list-disc '>
        <li className='font_normal text-[13px] mb-1'>This space is unique blend of IT office space or commercial office space.</li>
        <li className='font_normal text-[13px] mb-1'>Grade A Office space with support amenities</li>
        <li className='font_normal text-[13px] mb-1'>Hosur Road is a part of the National Highway 7 and connects Bengaluru to Hosur district in Tamil Nadu.</li>
        <li className='font_normal text-[13px] mb-1'>It is well-connected to the Outer Ring Road and Sarjapur Road.</li>
        <li className='font_normal text-[13px] mb-1'>NICE Road and important areas like BTM Layout are easily accessible.</li>
        <li className='font_normal text-[13px] mb-1'>HSR Layout is also well-linked to this area.</li>
        <li className='font_normal text-[13px] mb-1'>The Project is just 12 km from the city, 8 km from the HAL Airport, and on the way to Electronic City and Hi-Tech City.</li>
        <li className='font_normal text-[13px] mb-1'>Good neighborhood like, HGS, Emids, Bosch, Capgemini, tech mahindra, Decathlon, Infosys, Wipro, CGI, Hewlett Packard Enterprise, Siemens, ets. commercial real estate services</li>
       
    </ul>

</div>

<div className='md:w-[50%] mt-10 md:mt-0 md:ml-10'>
    <img src={side1} className='h-[200px] w-[100%] object-cover md:w-[80%]' />
    <img src={side2} className='mt-2 h-[200px] w-[100%] object-cover md:w-[80%]' />

</div>
</div>


        <div className='md:flex w-[90%] justifw-between my-10 md:w-[80%] mx-auto'>

            <div className='md:w-[50%]'>
                <h6 className='font_bold  text-[#000] ml-2 mb-2'>BUILDING AREA STATEMENT AND PROJECT BRIEF</h6>
                <ul className='ml-5 md:ml-10 mt-10 list-disc '>
                    <li className='font_normal text-[13px] mb-1'>Grade A building, with a floor plate of 10,048 Sqft</li>
                    <li className='font_normal text-[13px] mb-1'>Very good accessibility from all parts of Bangalore</li>
                    <li className='font_normal text-[13px] mb-1'>Better car parking facilities/ratio (basements )</li>
                    <li className='font_normal text-[13px] mb-1'>A grade floor with brand new interiors of high efficiency of (less than 75%)</li>
                    <li className='font_normal text-[13px] mb-1'>Built as per national building code lines, with the occupation certificate</li>
                    <li className='font_normal text-[13px] mb-1'>Good public transportation</li>
                    <li className='font_normal text-[13px] mb-1'>Very Good Signage Space,</li>
                    <li className='font_normal text-[13px] mb-1'>Power & power back up available</li>
                    <li className='font_normal text-[13px] mb-1'>Separate cafeteria space at the terrace</li>
                    <li className='font_normal text-[13px] mb-1'>Commercial Advantage</li>
                    <li className='font_normal text-[13px] mb-1'>24/7 security systems</li>
                </ul>

            </div>

            <div className='md:w-[50%] mt-10 md:mt-0 md:ml-10'>
                <img src={chart}  className='md:w-[80%] w-[100%]' />

            </div>
        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Interior Pictures</h6>
        <div className='grid grid-cols-1 pb-10 md:grid-cols-3 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <img src={interior1} className='w-[100%] h-[200px]' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <img src={interior2} className='w-[100%] h-[200px]' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <img src={interior3} className='w-[100%] h-[200px]' />
        </div>

        
        </div>

        <h6 className='text-center font_bold text-[18px] text-[#4190ac] my-10'>Floor Plans</h6>
        
        <div className='grid grid-cols-1 pb-10 md:grid-cols-3 w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>GROUND FLOOR PLAN</h6>
            <img src={ground_floor} className='w-[100%]' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px] uppercase'>Mezzanine FLOOR</h6>
            <img src={mezzaine_floor} className='w-[100%]' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Typical Floor -1st, 2nd & 3rd</h6>
            <img src={typical_floor} className='w-[100%]' />
        </div>

        
        </div>


        <div className='w-[80%] md:flex items-start  mx-auto '>
            {/* <img src={location_1} /> */}
            <div className='md:w-[50%] h-[300px]'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7779.011648512283!2d77.64800100000001!3d12.875163!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6b540b26fa2d%3A0x361fb3488089c883!2sMetropolis%20gurukrupa%2C%20Metropolis%20Gurukrupa%2C%20Service%20Rd%2C%20Aishwarya%20Crystal%20Layout%2C%20Singasandra%2C%20Bengaluru%2C%20Karnataka%20560068!5e0!3m2!1sen!2sin!4v1720677082912!5m2!1sen!2sin" className='w-full h-full' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className=' mt-10 md:mt-0 md:ml-10'>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>CBD: 12Kms</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Ozone Manny Tech Park: 1.8 Kms</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Silk Board  Junction 5Kms </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Electronic City Toll Gate: 2.5Kms </h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Live 100 Hospital: 150 Meters </h6>

                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='font_normal text-[12px]'>+91 9845062227 / info@fidelituscorp.com  </h6>
                    </div>

                  
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default GuruKrupa