import {BsArrowLeftCircle,BsArrowRightCircle} from 'react-icons/bs'
import CountUp from 'react-countup';

import about_img from '../folder/images/aboutimage.avif'

// services 
import corporate from '../folder/images/services/corporate-real-estate.jpeg'
import resedential from '../folder/images/services/resedential1.jpeg'
import industrial from '../folder/images/services/industrial_warehouse.webp'
import land from '../folder/images/services/lands1.jpeg'
import health from '../folder/images/services/health.webp'
import education from '../folder/images/services/education.webp'



import resedential1 from '../folder/images/services/resedential.jpg'
import industrial1 from '../folder/images/services/warehouse.jpg'
import land1 from '../folder/images/services/land_investment.jpeg'
import land2 from '../folder/images/services/land_service.jpg'




// services icon
import corporate_icon from '../folder/images/services_sub_icon/real_estate_icon.png'
import resedential_icon from '../folder/images/services_sub_icon/resedential_icon.png'
import industrial_icon from '../folder/images/services_sub_icon/warehouse_icon.png'
import land_icon from '../folder/images/services_sub_icon/land_icon.png'
import health_icon from '../folder/images/services_sub_icon/hospitality_icon.png'
import education_icon from '../folder/images/services_sub_icon/education_icon.png'


//why we succeed
import cloud from '../folder/images/services/we_are_best/cloud-database.png'
import conversation from '../folder/images/services/we_are_best/conversation.png'
import dedication from '../folder/images/services/we_are_best/dedication.png'
import on_time from '../folder/images/services/we_are_best/on-time.png'

// testimonials 
import ozone from '.././folder/images/testimonials/ozone.png'
import port53 from '.././folder/images/testimonials/port53.png'
import tata from '.././folder/images/testimonials/tata.png'
import transasia from '.././folder/images/testimonials/transasia.png'

// import coworking from '../folder/images/coworking.jpg'

import { Modal } from 'antd';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

import React,{useEffect, useRef, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';
import { bannerArr, bannerDescriptionArr, base_url, blogsArr, clientArr, department, succeedArr } from '../folder/constants/Appconstants';
import ErrorComponent from '../folder/constants/ErrorComponent';
import axios from 'axios';
import {message} from 'antd'
import moment from 'moment';

import { GetBlogServices } from '../folder/services/BlogServices';

import videoPlay from '../folder/images/FTS.mp4'
import videoPlay1 from '../folder/images/video.mp4'
import toast from 'react-hot-toast';


export default function Home() {

  const videoRef = useRef(null);

  const navigate = useNavigate()
  const [index,setindex] = useState(0)
  const [datas,setdatas] = useState({})


  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Attempt to play the video
      video.play().catch(error => {
       toast.error("Oops we couldn't play the video for you!")

        // Handle error (e.g., show a play button)
        console.log('Autoplay prevented. Showing play button.');
      });
    }
}, []);

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetBlogServices(1,'')
    setdatas(response?.data)
  }


  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  } 

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
    <BsArrowLeftCircle 
    className={`${className} -mt-0  md:-mt-2 `}
    style={{ ...style, display: "block",color:'#ddd',}}
    onClick={onClick} />
    );
  }
    
  function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <BsArrowRightCircle 
            className={`${className} -mt-0 md:-mt-2 `}
            style={{ ...style, display: "block",color:'#ddd',}}
            onClick={onClick} />
      );
  }

  const [modal,setmodal] = useState(false)

  const [data,setdata] = useState({name:'',mobile:'',email:'',requirment:'',department_website:''})
  const [error,seterror] = useState({name:'',mobile:'',email:'',requirment:'',department_website:''})


  const services = [
    {
      img:corporate,
      icon:corporate_icon,
      name:'Corporate Real Estate',
      path:'/services/corporate-real-estate'
    },
    {
      img:land2,
      icon:land_icon,
      name:'Land & Investment',
      path:'/services/land-investment'
    },
    {
      img:industrial1,
      icon:industrial_icon,
      name:'Warehousing & Industrial',
      path:'/services/industrial-warehousing'
    },
    {
      img:resedential1,
      icon:resedential_icon,
      name:'Resedential Real Estate',
      path:'/services/resedentail-leasing-rent-sale'
    },
    // {
    //   img:health,
    //   icon:health_icon,
    //   name:'Hospitality'
    // },
    // {
    //   img:education,
    //   icon:education_icon,
    //   name:'Education'
    // },

  ]

  const we_are_best = [
    {
      img:on_time,
      name:'On Time Process',
      val:'Our industry knowledge about Real Estate transactions helps to find and deliver your requirement on time.'
    },
    {
      img:dedication,
      name:'Dedicated Manager',
      val:'We will allot a dedicated expert who will be responsible to help and guide you with your requirement.'
    },
    {
      img:cloud,
      name:'Unmatched Database',
      val:'Our updated database will ensure that your requirements and needs are met to the highest standards.'
    },
    {
      img:conversation,
      name:'Proper Communications',
      val:'We believe in transparency, and our team will ensure that there is proper communication made to you in the process.'
    }
  ]

  const testimonials = [
    {
      id:1,
      name:'Shreehari -VP , Ozone Group',
      testimonial:'It was truly a commendable performance that too in the span of 4 months, we know these things do not happen without a great deal of effort, and I want you to know how much we appreciate your hard work.',
      img:ozone,
    },
    {
      id:2,
      name:'Ramesh - Sr.VP, TATA Power',
      testimonial:'The transaction experts of Fidelitus have done a fabulous job. Thank you for finding the most ideal office space for us.',
      img:tata,
    },
    {
      id:3,
      name:'Gnanasekar Velusamy VP – Research and Development',
      testimonial:'Fidelitus corp is very quick to understand the requirement just after one call and share a list of properties that were very relevant. Speedy and efficient visit to the shortlisted sights and negotiating on the finalized option. Patience you demonstrated when our organization took time to take the final call. Working closely with the landlord to get the contract terms finalized',
      img:transasia,
    },
    {
      id:3,
      name:'Karthik Kannaiah Country Director- India',
      testimonial:'It’s been an immense pleasure to partner with you to find our first office space in India. Being a startup and more particularly being a Cyber Security services organization our requirement stood unique. The team made sure to check all our boxes and stood by our side in negotiating and finding an apt address for our organization. I definitely recommend their assistance in choosing the right space for your organization.',
      img:port53,
    },
  ]

  const settings1 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    centerMode: true,
    cssEase: "linear",
    autoplaySpeed: 2000,
    centerPadding: '0px',
    autoPlay:true,
    prevArrow:<SampleNextArrow />,
    nextArrow:<SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          autoPlay:true
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoPlay:true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          autoPlay:true
        }
      }
    ]
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerMode: true,
    cssEase:"linear",
    autoplaySpeed: 2000,
    centerPadding: '0px',
    autoplay:true,
    prevArrow:<SampleNextArrow />,
    nextArrow:<SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 8,
          autoPlay:true
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay:true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay:true
        }
      }
    ]
  };


  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['department_website'] = department
      sendData['requirment'] = `Enquiry Form : ${data.requirment}`
      
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          setmodal(!modal)
          message.success('Enquiry Form Submitted Successfully')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:''})
        }
      }).catch((err)=>{      
        setmodal(!modal)
        message.success('Enquiry Form Submitted Successfully')
      }) 
    }
  }

  async function openDetail(v){
    navigate(`/blogs/detail/${v?._id}`)
  }

  return (
    <div >

      
      <Modal open={modal} closable={false} footer={false} width={350}>

      <div>
        <div>
          <h6 className='font-bold text-[16px]'>Like To Meet Us</h6>
          <h6 className='text-[13px] leading-[17px]'>We are always happy to assist you with all your queries just schedule a visit date so that our experts be ready to assist you! </h6>
        </div>


          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Name</h6>
            <input type='text' name="name" value={data?.name} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.name} />

          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Mobile</h6>
            <input type='text' name="mobile" value={data?.mobile} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.mobile} />

          <div className='mr-5 md:mt-2 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Email</h6>
            <input type='text' name="email" value={data?.email} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.email} />


          <div className='mt-2 md:mt-2 mr-5 w-full'>
            <h6 className='font_bold mb-1 text-[12px]'>Enquiry Form</h6>
            <textarea type='text' name="requirment" value={data?.requirment} onChange={handleChange} className='border w-full focus:ring-0 focus:outline-none p-1 pl-2 text-[13px]' />
          </div>
          <ErrorComponent error={error?.requirment} />



        <div className='flex items-center border-t pt-2 mt-4'>
          <h6 onClick={()=>setmodal(!modal)} className='text-[12.5px] cursor-pointer mr-2 font-bold border px-2 rounded py-1 text-center w-max'>Close</h6>
          <h6 onClick={submitForm} className='text-[12.5px] cursor-pointer font-bold border px-2 rounded py-1 text-center bg-[#027d9b] text-white w-max text-white'>Submit</h6>
        </div>

      </div>

      </Modal>

      <div className='relative'>
            {/* <Carousel 
              centerMode={true} 
              autoPlay={true} 
              infiniteLoop={true}
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[32vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                        <div className='w-full  h-[32vh] md:h-[91vh] bg-black/40 absolute  '/>

                        <div className='absolute left-[20%] md:left-[10%] top-[12%] md:top-[45%] w-[70%] md:w-[40%]'>
                           <h6 className='font_bold text-white text-left text-[22px] md:text-[30px]' style={{color:bannerDescriptionArr[i]?.color}}>{bannerDescriptionArr[i].heading}</h6>
                           <div className='w-[6px] absolute -left-[15px] top-[10px]  h-[140px] rounded bg-[#027d9b]'></div>
                           <h6 className='text-[13px] md:text-[17px] font_normal text-left text-white'  style={{color:bannerDescriptionArr[i]?.color}}>{bannerDescriptionArr[i].description}</h6>
                           <div>
                             <h6 onClick={()=>navigate(bannerDescriptionArr[i]?.path)} className='bg-[#e77817] cursor-pointer text-[14px] text-white mt-2 w-max rounded px-[10px] font_bold p-[5px]'>Know More</h6>
                           </div>
                        </div>
                    </div>    
                ))}




              
            </Carousel> */}
           
           <video autoPlay loop muted playsInline className="w-full md:h-[90vh] object-cover">
             <source src={videoPlay}  type="video/mp4"/> 
              {/* <source src={videoPlay1} type="video/mp4"/> */}
            </video>
      </div>

       
       
       <div className='py-20 bg-home_trans_bg bg-no-repeat bg-right-bottom md:flex items-center justify-between w-[90%] md:w-[80%] mx-auto'>
          <div  data-aos="fade-right">
            <img  width={500} height={500} src={about_img} />
          </div>
          <div className='mt-10 md:mt-0 md:w-[45%]'>

            <h6 data-aos="fade-up" className='text-[25px] font_bold  border-l-[5px] border-l-[#4190ac] pl-4'><strong>Who are we?</strong></h6>

            <h6 data-aos="fade-up" className='font_normal text-[15px] leading-[23px] mt-4 md:pl-6'>Fidelitus Corp is a front runner in the real estate sector. We provide world class end-to-end <span className='text-[#418eaa] font-[600]'>Real
            estate solutions</span> to its vast group of patrons. We as a team, have been working from Bengaluru
            for the past decade. </h6>

            <h6 data-aos="fade-up" className='font_normal text-[15px] leading-[23px] mt-4 md:pl-6'>We help you purchase, build, occupy and invest in an assortment of assets
            including industrial, commercial, retail and residential real estate. We’re here to make worthwhile
            opportunities and magnificent spaces far and wide where corporates and individuals can
            achieve their aspiration. </h6>


            <h6 data-aos="fade-up" onClick={()=>navigate('/about')} className='transition font_normal delay-300 duration-300 ease-in-out text-[12px] font-[500] bg-[#e67816] px-2 py-2 mt-5 md:ml-6 text-center text-white cursor-pointer hover:bg-gray-100 w-[100px]'>KNOW MORE</h6>

           
          
          </div> 
      </div>

      <div className='border-t  border-gray-100 mt-5   md:pb-0'>
          <div className='w-[90%] md:w-[80%] grid grid-cols-2 md:grid-cols-4 mx-auto'>
             <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white  border-r border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold  text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={10} />M+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Total Sq.Ft areas</h6>
             </div>

             <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white sm:border-none md:border-r border-gray-100 '>
               <h6 className='text-center  text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={680} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Clients Served</h6>
             </div>

             <div className='p-2 flex flex-col border-t items-center justify-center w-full py-5 bg-white md:border-l border-r border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={4} end={390} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Property Sold</h6>
             </div>

             <div className='p-2 flex flex-col border-t items-center justify-center w-full py-5 bg-white  border-gray-100'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={5} separator="," end={10} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Years In Industry</h6>
             </div>
          </div>
       </div> 

      <div className='py-20 bg-gray-50 md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6  data-aos="fade-right" className='text-center text-[13px] font_normal md:ml-6 text-gray-400 mb-2'>Our services</h6>

        <h6  data-aos="fade-up" className='font_bold text-center text-[25px]  mb-2 border-l-[#4190ac] md:pl-4'>Real Estate Services Like Never Before</h6>

         <h6  data-aos="fade-up" className='font_normal text-[14px] md:ml-3  md:pl-4 mb-10 text-center' >From us, you can expect nothing less than brilliant client support, delivered by qualified and experienced professionals. We are building a better future for our clients, our people, and our communities. Our clients come from banking, energy, healthcare, law, life sciences, manufacturing, technology, FMCG, logistics, and various other sectors.</h6>

        <div className='grid md:grid-cols-2 gap-5'>
          {services?.map((s,i)=>(
            <div data-aos="fade-up" onClick={()=>navigate(s?.path)} key={i} className='group after:bg-[#4190ac] h-[280px] overflow-hidden cursor-pointer relative'>
                 <img src={s?.img} className='w-[100%] h-[280px] ' />

                 <h6 className='absolute bg-white  p-1 top-1 left-1 font_normal text-[12px]'>{s?.name}</h6>
                 <div className='inline-block bg-[#4190ac]/50 duration-300 transition-all group-hover:-translate-y-[280px] h-[280px] min-w-[100%] min-height-[100%] '>
                    <div className='transition-all duration-300 text-[#fff] '>
                      <div className='p-2 flex min-h-[200px] items-center justify-center'>  
                        <div className='flex items-center'>
                        <img src={s?.icon} className='max-w-[20px] max-h-[20px] object-contain' />  
                        <h6 className='font_normal text-[12px] ml-4 w-[50%]'>{s?.name}</h6>
                        </div>
                      </div>
                    </div>
                 </div> 
            </div>  
          ))}

        </div>

        </div>
      </div>


      <div className='py-20 bg-home_trans_bg bg-no-repeat bg-right-bottom md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>

        <h6 data-aos="fade-right"  className='text-[13px] font_normal md:ml-6 text-gray-400 mb-2'>An Organized and Optimzed flow is the key to our success</h6>
        <h6 data-aos="fade-right"  className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] md:ml-5 mb-3 pl-4'>How do we succeed</h6>
        <h6 data-aos="fade-up"  className='font_normal text-[14px] md:ml-3  md:pl-4'>Fidelitus Corp with its aggressive expansion plans have recruited more professionals and upgraded to larger premises with larger accounts. All this is attained through process-driven methodologies</h6>


        <div className='grid grid-cols-3  md:grid-cols-7 md:gap-5 mt-5'>
          {succeedArr?.map((s,i)=>(
            <div data-aos="fade-up" key={i} className='p-5 flex flex-col items-center justify-center cursor-pointer relative'>
                 <img src={s?.img} className='h-[60px] object-contain' />
                  <h6 className='font_bold text-[12px] text-center mt-2'>{s?.value}</h6>
            </div>  
          ))}

        </div>

        </div>
      </div>
      

      <div className='py-20 bg-gray-50 md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>

        <h6 data-aos="fade-right" className='text-[13px] font_normal md:ml-6 text-gray-400 mb-2'>We have strong and skilled team that ensures client satisfaction. </h6>
        <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] md:ml-5 mb-3 pl-4'>Why we are the best</h6>
        <h6 data-aos="fade-up" className='font_normal text-[14px] md:ml-3  md:pl-4'>Fidelitus Corp with its aggressive expansion plans have recruited more professionals and upgraded to larger premises with larger accounts. All this is attained through process-driven methodologies</h6>

        <div className='grid md:grid-cols-4 gap-5 mt-5'>
          {we_are_best?.map((s,i)=>(
            <div data-aos="fade-up" key={i} className='group shadow-lg bg-white p-5 flex flex-col items-center justify-center cursor-pointer relative'>
                 <img src={s?.img} className='h-[80px] object-contain' />
                  <h6 className='font_bold text-[14px] mt-2'>{s?.name}</h6>
                  <h6 className='font_normal text-[12.5px] mt-1'>{s?.val}</h6>
            </div>  
          ))}

        </div>

        </div>
      </div>

      <div className='py-20 bg-home_trans_bg bg-no-repeat bg-left-top  md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>

        <h6 data-aos="fade-right" className='text-[13px] font_normal ml-6 text-gray-400 mb-2'>Clients Feedback. </h6>
        <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>Testimonials</h6>
        <h6 data-aos="fade-up" className='font_normal text-[14px] ml-3  pl-4'>What do people like about Fidelitus Real Estate Transactions?</h6>
        
        <div className='mt-10'>
        <Slider {...settings1}>

        {testimonials?.map((c)=>(
                <div data-aos="fade-up" key={c?.id} className='p-2 border border-slate-50 shadow max-w-[95%]  mr-[2%] bg-white flex items-center justify-center focus:outline-none focus:ring-0'>
                  <img alt={c?.img} className='w-full h-14 rounded object-contain  focus:outline-none focus:ring-0' src={c?.img}  />
                  <div className='mt-4'>
                    <h6 className='text-[14px] font-[900] font_normal'>{c?.name}</h6>
                    <h6 className='text-[12.5px] text-slate-700 mt-1 font_normal'>{c?.testimonial}</h6>
                    {/* <h6 className='text-[10px] mt-1 text-slate-600'>Date :  {c?.date}</h6> */}
                  </div>
                </div>
                ))}
        </Slider> 
        </div>       


        </div>
      </div>

      <div className='pb-20  bg-home_trans_bg bg-no-repeat bg-left-top  md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>

        <h6 data-aos="fade-right" className='text-[13px] font_normal ml-6 text-gray-400 mb-2'>Some of our clients. </h6>
        <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>Our Clients</h6>
        <h6 data-aos="fade-up" className='font_normal text-[14px] ml-3  pl-4'>We have served more than 700 clients all over India.</h6>
        
        <div className='mt-10'>

        <div className='md:px-0 w-[100%] my-5 md:w-[90%] mx-auto items-center'>
            <Slider {...settings}>
              {clientArr?.map((t)=>(
                <div data-aos="fade-up" key={t?.id} style={{mixBlendMode:'multiply'}} className='flex relative items-center justify-center focus:outline-none focus:ring-0 '>
                <img src={t} style={{mixBlendMode:'multiply'}} className='focus:outline-none w-[70%] h-[120px] object-contain focus:ring-0 ' />
                </div>

              ))}
              </Slider>
        </div>


        {/* <div className='md:flex border-t border-b py-5  border-slate-100 items-center justify-center'>
           <h6 className='font_bold text-[20px] mr-[10%]'>We Are Associated With</h6>
           <img className='mt-5 md:mt-0  animate-bounce' src={ciril} />
        </div> */}

       </div>
      </div>  
      </div>


      <div className='pb-20 bg-home_trans_bg bg-no-repeat bg-left-top  md:flex items-center justify-between w-[100%] mx-auto'>
        <div className='w-[90%] md:w-[80%] mx-auto'>

        <h6 data-aos="fade-right" className='text-[13px] font_normal ml-6 text-gray-400 mb-2'>Latest Blogs. </h6>
        <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>Blogs</h6>
        <h6 data-aos="fade-up" className='font_normal text-[14px] ml-3  pl-4'>Read the latest and most interesting blogs on Real Estate and Industry trends.</h6>
        
        <div className='mt-10 md:mx-5 md:w-[100%] grid gap-5 grid-cols-1 md:grid-cols-3'>
      
              {datas?.datas?.slice(0,3)?.map((b)=>(
              <div data-aos="fade-up"  className='mb-5 md:w-[100%] md:mb-0' onClick={()=>openDetail(b)}>
                  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='h-[170px] w-[100%]' />
                  <h6 className='font_bold text-[13px] mt-3'>{b?.title}</h6>
                  <h6 className='truncate font_normal text-gray-500 text-[12px] mt-1'>{b?.motive}</h6>
                  <div className='flex items-start mt-1 border-t pt-1.5 border-gray-100'>
                  {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
                  <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`} className='w-[30px] h-[30px] object-contain bg-slate-100 rounded-full' />
                  :
                  <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
                  }
                  <div className='ml-2 -mt-1'>
                  {(b?.written_by !== null && b?.written_by !== undefined) && <h6 className='font_bold  text-[#4190ac] text-[12px] mt-1'>{b?.written_by?.name}</h6>}
                  <div className='flex items-center -mt-1'>
                      <h6 className='font_normal  text-[10px] mt-1'>{moment(b?.date)?.format('LL')}</h6>
                      <h6 className=' ml-2 font_bold  text-[10px] mt-1'>{b?.min_read}</h6>
                  </div>
                  </div>    

                  </div> 
              </div>   
                ))}

        {/* </Slider>  */}
        </div>       


        </div>
      </div>

       <div className='py-20 bg-coworking_bg bg-no-repeat bg-right-top bg-contain  md:flex items-center md:h-[350px] justify-between w-[100%] mx-auto'>
        <div className='w-[95%] md:w-[80%] mx-auto '>
          <div className='mt-[170px] md:mt-0  md:w-[45%]'>
            <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>Looking For Co-working Space</h6>
            <h6 data-aos="fade-right" className='font_normal text-[14px] ml-3  pl-4'>We offer options that will provide a comfortable and conducive co-working space as per your expectation.</h6>
            {/* <h6 data-aos="fade-right" className='font_normal mt-3 text-[14px] ml-3  pl-4'>Connect with us so we provide a greater options in co-working space.</h6> */}
            <h6 onClick={()=>setmodal(!modal)} data-aos="fade-up" className='transition font_normal delay-300 duration-300 ease-in-out text-[11px] font-[500] bg-[#e67816] px-2 py-1.5 mt-5 ml-6 text-center text-white cursor-pointer hover:bg-gray-100 w-[110px]'>ENQUIRY NOW</h6>
            
          </div>
        </div>
       </div>    




        
        
       
    </div>
  )
}
