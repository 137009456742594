

import {BiLogoLinkedin} from 'react-icons/bi'
import {IoLogoWhatsapp} from 'react-icons/io'
import {IoMdCall} from 'react-icons/io'
import {AiOutlineMail} from 'react-icons/ai'
import CountUp from 'react-countup';


// import about_img from '../folder/images/aboutimage.avif'
import about_img from '../folder/images/brigadepark.jpeg'

// import ValueImg from '../../folder/images/value.png' 
// import ExperitseImg from '../../folder/images/expertise.png' 
// import VisitorImg from '../../folder/images/placeholder.png' 
// import SelectedImg from '../../folder/images/guest-list.png' 
import Typist from 'react-typist';


// import where_we_Add_value1 from '../../folder/images/WHERE-WE-ADD-VALUE1.png'
// import where_we_Add_value2 from '../../folder/images/WHERE-WE-ADD-VALUE2.png'
// import where_we_Add_value3 from '../../folder/images/WHERE-WE-ADD-VALUE3.png'


import sandeep from '../folder/images/teams/sandeep.png'
import pramod from '../folder/images/teams/pramod.png'
import ramdas from '../folder/images/teams/ramdas1.png'
import deepa from '../folder/images/teams/deepa.png'
import shreeshail from '../folder/images/teams/shreeshail1.png'
import abhith from '../folder/images/teams/abhith.png'
import { useNavigate } from 'react-router-dom'

//why we succeed
import cloud from '../folder/images/services/we_are_best/cloud-database.png'
import conversation from '../folder/images/services/we_are_best/conversation.png'
import dedication from '../folder/images/services/we_are_best/dedication.png'
import on_time from '../folder/images/services/we_are_best/on-time.png'
import { achuthGowda } from '../folder/constants/Appconstants';



function About() {

  const navigate = useNavigate()

  const employee = [
    // {
    //   name:'Achuth Gowda',
    //   des:'Founder & Managing Director',
    //   linkedIn:'https://in.linkedin.com/in/achuth-gowda-mrics-88b9a26',
    //   img:achuthGowda,
    //   whatsapp:'https://api.whatsapp.com/send?phone=919845083654&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
    //   gmail:'mailto:achuth.gowda@fidelituscorp.com',
    //   phone:'tel:+91 9845083654'
    // },
    {
      name:'PRAMOD  GP',
      des:'Director - Transaction Services',
      linkedIn:'https://www.linkedin.com/in/pramod-gp-3805a592/',
      img:pramod,
      whatsapp:'https://api.whatsapp.com/send?phone=919845083654&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
      gmail:'mailto:pramod.gp@fidelituscorp.com',
      phone:'tel:+91 9845083654'
    },
        {
          name:'SANDEEP JAYARAJ',
          des:'Director - Transaction Services',
          linkedIn:'https://www.linkedin.com/in/sandeep-jayaraj-7bba1b1b1/',
          img:sandeep,
          whatsapp:'https://api.whatsapp.com/send?phone=919845036943&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:sandeep.jayraj@fidelituscorp.com',
          phone:'tel:+91 9845036943',
        },
       
        {
          name:'DEEPA KRISHNAREDDY',
          des:'VP – Transaction Services',
          linkedIn:'https://www.linkedin.com/in/deepa-krishnareddy-9870aa180/',
          img:deepa,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:deepa.k@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        },
        {
          name:'SHREESHAIL M KOTAGI',
          des:'VP - Transaction Services',
          linkedIn:'https://www.linkedin.com/in/shreeshail-kotagi-35980511a/',
          img:shreeshail,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:shreeshail.kotagi@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        },
        {
          name:'RAMDAS RAI',
          des:'VP -Transaction Services',
          linkedIn:'https://www.linkedin.com/in/ramdas-rai-708928136/',
          img:ramdas,
          whatsapp:'https://api.whatsapp.com/send?phone=919845090850&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:ramdas.rai@fidelituscorp.com',
          phone:'tel:+91 9845090850'
        },
        {
          name:'ABHITH KUMAR M P',
          des:'VP – Transactions Services',
          linkedIn:'https://www.linkedin.com/in/abhith-kumar-m-p-b543787a/',
          img:abhith,
          whatsapp:'https://api.whatsapp.com/send?phone=919591847914&text=Hi%20I%20would%20like%20to%20connect%20with%20you',
          gmail:'mailto:abhith.kumar@fidelituscorp.com',
          phone:'tel:+91 9591847914'
        }
  ]


  const we_are_best = [
    {
      img:on_time,
      name:'On Time Process',
      val:'Our industry knowledge about Real Estate transactions helps to find and deliver your requirement on time.'
    },
    {
      img:dedication,
      name:'Dedicated Manager',
      val:'We will allot a dedicated expert who will be responsible to help and guide you with your requirement.'
    },
    {
      img:cloud,
      name:'Unmatched Database',
      val:'Our updated database will ensure that your requirements and needs are met to the highest standards.'
    },
    {
      img:conversation,
      name:'Proper Communications',
      val:'We believe in transparency, and our team will ensure that there is proper communication made to you in the process.'
    }
  ]


 


  return (
    <div className="w-full h-full overflow-x-hidden relative z-0">

     

      <div className='relative flex items-center justify-center bg-about_bg bg-top bg-cover h-[200px]  md:h-[340px]'>
        <div className='z-10'>
          <h6 className='text-[30px] text-white text-center tracking-[7px] font_thicker opacity-80'>ABOUT US </h6>
          <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / About Us </h6>
          <h6 className='text-[13px] mt-1 w-[80%] mx-auto text-white text-center font_normal opacity-80'>We&apos;re expertise Real Estate Service Provider. We provide a solution that client required in a quicker manner. </h6>
        </div>
        <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
        </div> 
      </div>


      <div className="w-[90%] bg-home_trans_bg bg-no-repeat bg-right-bottom md:w-[80vw] md:flex mx-auto pt-20 ">
          <div className='md:w-[50%] '>
            <img   width={500} height={500} src={about_img} />
          </div>
          <div className='mt-10 md:w-[50%] md:mt-0 '>
          <h6 data-aos="fade-left" className="text-left  font-[400] font_bold text-[24px]">About  <span className='text-[#4190ac]'>Us</span></h6>
          <h6 data-aos="fade-left" className='text-[14px] text-left  mt-5 font_normal '>The company has its own leadership, vision, culture and business. We follow international standards in offering transaction services. We have expanded our branches in Mumbai, Pune, Delhi, Ahmedabad, Kolkata, Hyderabad and Chennai</h6>
          <h6 data-aos="fade-left" className='text-[14px] text-left  mt-5 font_normal '>From us, you can antedate nothing less than brilliant client support, conveyed by qualified as well as experienced experts. We are building an enhanced tomorrow for our clienteles, our people and our communities. Our clients come from banking, energy, healthcare, law, life sciences, manufacturing, technology, FMCG, logistics segments and various other sectors.</h6>
          </div>  
      </div>
      <div className='border-t  border-gray-200 mt-5   md:pb-0'>
          <div className='w-[90%] md:w-[70%]  grid grid-cols-2 md:grid-cols-4 mx-auto'>
             <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white  border-r border-gray-200'>
               <h6 className='text-center text-[#4190ac] font_bold  text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={10} />M+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Total Sq.Ft areas</h6>
             </div>

             <div className='p-2 flex flex-col items-center justify-center w-full py-5 bg-white sm:border-none md:border-r border-gray-200 '>
               <h6 className='text-center  text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={2.5} end={680} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Clients Served</h6>
             </div>

             <div className='p-2 flex flex-col border-t md:border-t-0 items-center justify-center w-full py-5 bg-white md:border-l border-r border-gray-200'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={4} end={390} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Property Sold</h6>
             </div>

             <div className='p-2 flex flex-col border-t md:border-t-0 items-center justify-center w-full py-5 bg-white  border-gray-200'>
               <h6 className='text-center text-[#4190ac] font_bold text-[30px]'><CountUp enableScrollSpy={true} scrollSpyOnce={true} delay={1000} duration={5} separator="," end={10} />+</h6>
               <h6 className='text-[12px] text-center font-[500] font_normal text-slate-700 uppercase'>Years In Industry</h6>
             </div>
          </div>
       </div> 


      <div className='bg-slate-100 md:px-[11.65%] mt-0'>
          <div className="w-[90%] md:w-[80vw] md:flex justify-between mx-auto ">
              <div data-aos="fade-right"  className='w-full md:w-[48%] md:border-r border-b md:border-b-0 pr-[5%] py-10 md:py-10'>
                  <h1 className='text-[16px] border-l-4 border-[#e58c3d] font_bold pl-2'>Our Vision</h1>
                  <h6 className='text-[13px] mt-5 pl-2 text-black font_normal'>We are more than just a collection of businesses; we are a family of diverse ventures united under a single brand. By embracing innovation, collaboration, and social impact, we will drive positive change, leaving a mark of excellence and inspiration in every community we serve. we envision a world where diverse business verticals coexist under one unified brand, sharing resources and expertise to create a positive impact in people's lives.</h6>
              </div>

              <div data-aos="fade-left" className='w-full md:mt-0 md:w-[48%] py-10 md:py-10'>
                  <h1 className='text-[16px] border-l-4 border-[#e58c3d] font_bold pl-2'>Our Mission</h1>
                  <h6 className='text-[13px] mt-5 pl-2 text-black font_normal'>At Fidelitus Corp, our mission is to empower businesses and communities through our comprehensive and end-to-end services. With unwavering dedication to excellence, we identify, design, and execute solutions that optimize the functionality, comfort, and safety of spaces, enriching the lives of individuals and organizations alike. </h6>
              </div>
          </div>
        
      </div>

      <div >
        <h6 data-aos="fade-left" className='text-center pt-10 font_bold text-[16px]'>Bringing Value Through Quality, Expertise, and Access</h6>

        <h6 data-aos="fade-left" className='text-[13px] w-[90%] md:w-[90vw] mx-auto font_normal text-center mt-5  text-slate-700' >At <span className='text-[#4190ac] font_bold'>Fidelitus Real Estate Transactions</span>, we take pride in enriching your association with us with our unwavering commitment to quality, strong expertise, and access to industry insights. Here&apos;s how we stand out</h6>

        <div className='py-20 border-b border-slate-100 items-center bg-home_trans_bg bg-no-repeat bg-right-bottom justify-between w-[100%] mx-auto'>

      


        <div className='w-[100%]  md:w-[80%] mx-auto'>

          <h6 data-aos="fade-right" className='text-[13px] font_normal ml-6 text-gray-400 mb-2'>We believe in hard work. </h6>
          <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] ml-5 mb-3 pl-4'>We Believe</h6>
          <h6 data-aos="fade-up" className='font_normal px-8 text-[14px] mb-20'>Our success depends on having a thorough understanding of the Indian real estate market before applying international standards to each client’s project. We create cutting-edge company space solutions and maintain a database of floors that are prepared for our clients to new ventures. Along with assisting clients with relocation, we also aid them in expanding the number of offices they require. For clients to benefit the most from them, each of our office locations has been carefully chosen. With cutting-edge conveniences and customized interiors, we turn bare floors into workspaces. Our knowledgeable crew assists you with all your needs because we know them all.</h6>


          <h6 data-aos="fade-right" className='text-[13px] font_normal md:ml-6 text-gray-400 mb-2'>We have strong and skilled team that ensures client satisfaction. </h6>
          <h6 data-aos="fade-right" className='font_bold text-[25px] border-l-[5px] border-l-[#4190ac] md:ml-5 mb-3 pl-4'>Why we are the best</h6>
          <h6 data-aos="fade-up" className='font_normal text-[14px] md:ml-3  md:pl-4'>Fidelitus Corp with its aggressive expansion plans have recruited more professionals and upgraded to larger premises with larger accounts. All this is attained through process-driven methodologies</h6>

          <div className='w-[80%] md:w-[100%] mx-auto grid md:grid-cols-4 gap-5 mt-5'>
            {we_are_best?.map((s,i)=>(
              <div  data-aos="fade-up" key={i} className='group shadow-lg bg-white p-5 flex flex-col items-center justify-center cursor-pointer relative'>
                  <img src={s?.img} className='h-[80px] object-contain' />
                    <h6 className='font_bold text-[14px] mt-2'>{s?.name}</h6>
                    <h6 className='font_normal text-[12.5px] mt-1'>{s?.val}</h6>
              </div>  
            ))}

          </div>

        </div>
        </div>
        
      </div>



      <div className='md:flex items-center border-b border-slate-100 justify-center md:w-[100vw] px-[5%] md:px-[10%] py-[5%] mx-auto mt-10'>
        <div className='md:w-[50%] h-[110%]'>
        <img alt="achuthGowda" src={achuthGowda} className='overflow-hidden  w-[100%] h-[120%] object-cover mx-auto  mt-4 transition duration-500 cursor-pointer  mix-blend-multiply' />
        </div>
        <div className='md:w-[70%] mt-10 md:ml-10'>
            <h6 className="text-[17px] font_bold">Achuth Gowda</h6>
            <h6 className="text-[10px] text-slate-400 font_bold">Founder and Managing Director</h6>
            {/* <h6 data-aos="fade-up" className="text-[14px] mt-4 font_normal">Professional Member of The Royal Institution of Chartered Surveyors (MRICS) has a vision to make Fidelitus Corp a leading service provider in corporate real estate industry across verticals. He provides leadership and direction to ensure the company achieves its strategic objectives. </h6> */}
            <h6 data-aos="fade-up" className="text-[14px] mt-4 font_normal">Under his leadership, Fidelitus Corp has grown to become a leader in commercial real estate transactions and a trusted advisory real estate company in Bangalore. His leadership skills since 2013 has led to the companies growth and branch out into many verticals and grow in each one of them. His business acumen has lead the company to develop its core services as well as increase its revenue from other domains. Achuth Gowda is the recipient of the prestigious Nada Prabhu Kempegowda Award 2020.</h6>

            <h6 data-aos="fade-up" className="text-[14px] mt-4 font_normal">A Founder is the first employee of the organization and when they treat even employee as an EMPLOYER those individuals develop a sense of responsibility & belongingness towards the company and the success derived out of such efforts transcends to a whole new level!</h6>
        </div>
      </div>

      {/* bg-slate-100  */}

      <div className="w-[100%] md:w-[100vw] px-[5%] md:px-[10%] py-[5%] mx-auto mt-10 ">
          <h6 data-aos="fade-left" className="text-center  font-[400] font_bold text-[24px]">Our Team  <span className='text-[#4190ac]'>Experts</span></h6>
          <h6 data-aos="fade-left" className="text-[14px]  text-center my-5 text-slate-700 font_normal">We believe our strength is in our people. We have assembled the best people in the Real Estate service industry, who all share a common passion for delivering excellent service to our colleagues and clients. We all believe that national Real Estate service should be easy.</h6>
          
          <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:py-10 md:px-20">
          
          {employee?.map((e,i)=>(
            <div className='bg-white mb-2 p-2 relative'>
            <div data-aos="fade-up" key={i} className='border  w-[100%] md:min-h-[240px] max-h-[240px] bg-[#d8d8d8] grid border-[#f3f3f3] place-items-center relative overflow-hidden   md:mx-0 pb-2'> 
              <img alt={e?.name} src={e?.img} className='overflow-hidden w-[100%] h-[100%] object-cover mx-auto  mt-4 transition duration-500 cursor-pointer  mix-blend-multiply' />
            </div>  
            <div className='backdrop-filter  bg-white/60 backdrop-blur-sm p-1 md:p-2 w-[90%]  bottom-4 left-[5%] absolute'>
                      <h6 className='text-[11px] md:text-[13px] text-[#000] text-center capitlaize  mt-2 font_bold font-[700]'>{e?.name}</h6>
                      <h6 className='text-[11px] text-center text-[#000] font_normal  font-[500]'>{e?.des}</h6>

                     
              </div>  
              </div>
          ))}


 {/* <div className='border-t mt-2 text-black min-w-[120px] w-[100%]'>
                      <span className='flex justify-center mt-2 w-[80%] mx-auto text-slate-400'>
                          <a className='h-full' href={e?.linkedIn} target='_blank'>
                          <BiLogoLinkedin size={20} className='px-[2px] mx-[10px] text-black hover:text-white p-[4px] hover:bg-[#4190ac] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e?.gmail} target='_blank'>
                          <AiOutlineMail size={20} className='px-[2px] mx-[10px] text-black hover:text-white p-[4px] hover:bg-[#4190ac] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e?.phone} target='_blank'>
                          <IoMdCall className='px-[2px] cursor-pointer' />
                          </a>
                          <a className='h-full' href={e.whatsapp} target='_blank'>
                          <IoLogoWhatsapp className='px-[2px] cursor-pointer' />
                          </a>

                      </span>
                      </div>  */}

          </div>

        <h6 data-aos="fade-left" className='my-10 font_bold md:mt-0 text-[14px]  text-center w-[90%] md:w-[90%] md:w-[100%] mx-auto  ' >&quot; Team Leaders who motivate there team even in their bad time are the true leaders but we are fortunate to have many people like them. &quot;</h6>

      </div>





    
   


    

    </div>
  )
}

export default About