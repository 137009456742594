import axios from "axios";

export const PublicApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const PrivateApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

PrivateApiClient.interceptors.request.use(function (config) {
  return config;
});

PrivateApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const res = error.response;
    console.log("this is error", res.message);
    if (res.status == 401 || res.status == 403) {
      window.location.href = "/login";
    }
    console.error(`Looks like there was a problem. Status Code: ${res.status}`);
    return Promise.reject(error);
  }
);

PublicApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const res = error.response;
    console.log(`Looks like there was a problem. Status Code: ${error}`);
    
    return Promise.reject(error);
  }
);

