import React from 'react'
import { useNavigate } from 'react-router-dom'
import { service_section11, service_section12, service_section13, service_section14, service_section15, service_section16 } from '../../folder/constants/Appconstants'

function Corporaterealestate() {
  const navigate = useNavigate()

  const datas = [
          {
            img:service_section11,
            heading:'Tenant Representation',
            description:"Corporate real estate services includes tenant representation with sole focus on the tenant's best interests, we help you make an informed decision that best fits your corporate needs. We assist through providing relevant market knowledge, lease negotiation and renewal, micro and macro location analysis and much more to help you choose the right address!"
          },
          {
            img:service_section12,
            heading:'Landlord representation',
            description:"We closely partner with landlords and developers to maximize their asset value by creating persuasive and result-oriented leasing programs."
          },
          {
            img:service_section13,
            heading:'Lease structuring',
            description:"Strategic leasing advise for your property; we deliver tailor made solutions for your corporate needs including lease management, optimizing the tenant mix that add to your bottom line, improving the rental profile and much more."
          },
          {
            img:service_section14,
            heading:'Lease renewal and rent review',
            description:"With the aim to ensure transparency, both parties are encouraged to review the rent/lease terms and with our expertise we ensure a smooth hustle free transaction."
          },
          {
            img:service_section15,
            heading:'Exclusive marketing',
            description:"Unlock exclusive marketing strategies tailored for corporate real estate with  Fidelitus Corp Our bespoke approach leverages targeted campaigns, industry insights, and a network of qualified buyers and tenants to showcase your properties to the right audience, ensuring maximum exposure and optimal results."
          },
          {
            img:service_section16,
            heading:'Built to suit',
            description:"We assist you in setting up tailored commercial spaces that fit your business requirements, to ensure you are able to maximise the operational space. We assist you in expanding your new facility to meet your corporate goals."
          }
  ] 
  
  
  return (
    <div>
          <div className='relative flex items-center justify-center bg-corporate-service-banner_bg bg-center bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[5px] font_thicker opacity-80'>CORPORATE REAL ESTATE </h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigate('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Corporate Real Estate </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80'>We are helping organizations thrive in their respective domains.!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
         </div>
        
        
        <div className='px-[5%] md:mx-[10%] gap-5 grid grid-cols-1 py-[5%]'>
          {/* {datas?.map((d)=>(
            <div data-aos="fade-up" className='pt-5'>
               <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
               <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>    
          ))} */}
           {datas?.map((d,i)=>(
          <div className={`md:flex mb-5 ${i % 2 === 0 && 'flex-row-reverse'}`}>
            <img data-aos={i % 2 === 0 ?  "fade-right" : "fade-left"} src={d?.img} className='w-[100%] md:min-w-[50%] md:max-w-[50%] h-[200px] md:h-[250px] object-stretch' />

            <div data-aos="fade-up" className={`pt-5 ${i % 2 === 0 ? 'md:mr-[5%]' : "md:ml-[5%]"}`}>
              <h6 className='text-[15px] font_bold'>{d?.heading}</h6>
              <h6 className='text-[14px] font_normal'>{d?.description}</h6>
            </div>  
          </div>))}
        </div>



    </div>
  )
}

export default Corporaterealestate