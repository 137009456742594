import React from 'react'
import { noDataLogo } from '../folder/constants/Appconstants'

function NoData() {
  return (
    <div className='py-[5%] flex flex-col items-center justify-center'>
     
        <img data-aos="fade-left" src={noDataLogo} className='h-[200px] object-contain' />
        <div data-aos="fade-up" className=' -mt-28 absolute ' >
        <h1 className='font_thicker text-[120px] opacity-20  md:text-[150px] text-[#027d9b]' >404</h1>
        </div>
        <h6 data-aos="fade-up" className='font_bold mt-5 md:mt-6'>So Sorry</h6>
        <h6 data-aos="fade-up" className='font_normal text-[13px]'>We Couldn't find what were you looking for</h6>
        <h6 data-aos="fade-up" className='font_bold text-[12px] border mt-2 mb-5 w-[100px] text-center p-[5px] border-black hover:bg-black hover:text-white cursor-pointer duration-300'>Need Support</h6>
       
    </div>

  )
}

export default NoData