import React,{useState} from 'react'

import banner1 from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-4.jpeg'
import banner2 from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-1.jpeg'
import banner3 from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-2.jpeg'
import banner4 from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-3.jpeg'

import master_plan from '../../folder/images/exclusive/shilpa_ananaya/Estate-Building-Print.png'
import basement_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-Basement-Plan.png'
import ground_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-Ground-Floor.png'
import first_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-1st-Floor.png'
import second_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-2nd-Floor.png'
import third_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-3rd-Floor.png'
import fourth_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-4-5-6-7th-Floor.png'
import eight_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-8th-Floor.png'
import terrace_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-Terrace-Plan.png'
import typical_floor from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-TYPICAL-FLOOR-768x631.png'
import section_plan from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-section-Plan.png'


import location_1 from '../../folder/images/exclusive/shilpa_ananaya/Shilpa-Ananya-Location-e1653647355185-1024x542.jpeg'

import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import GoBack from '../../folder/components/GoBack'

function ShilpaAnanya() {

  const bannerArr = [banner2,banner3,banner4,banner1]  
  const [index,setindex] = useState(0)

  return (
    <div>
    <GoBack /> 
    <div className='py-10'>
        <div className='flex items-center justify-center my-2'>
        <h6 className='font_bold text-[#f6821e]'>SHILPA ANANYA</h6>
        </div>
        <h6 className='font_bold text-[15px] text-center md:text-[25px] mt-2 uppercase'>Electronic City Phase-1 , Bengaluru, </h6>
         


        <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              infiniteLoop={true}
              interval={5000}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  mt-10 md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                    </div>    
                ))}




              
            </Carousel>

      
            <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Overview</h6>
        <h6 className='font_normal text-[13px] my-10'>A world class tech campus, specially designed to suit the needs of large IT/ITES operations. Conveniently located off Hosur Road Next to Infosys Campus at Electronic city Phase 1, a short drive from the Silkboard and part of Bengaluru’s fast developing IT suburb. Eight brilliantly planned blocks, offering a total of 2,46,337 Sq.ft of development on 85,302 of land parcel. Clean lined glass and steel aesthetics, large floor plates, adequate open landscaped areas and congregational spaces, and state of the art building management systems, all combine to present an address that truly matches your brand value.</h6>
        </div>


        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Project Highlights</h6>
        <div className='grid grid-cols-2 md:grid-cols-5 border-l border-r border-t border-b w-[90%]  md:w-[80%] mx-auto'>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Total built-up area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2,46,337 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>No.of.floors</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>2 Basements + Ground + 8 Floors + Terrace</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Per floor area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>85,302 Sqft</h6>
            </div>
            <div className='border-r border-b md:border-b-0 border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Development area</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Commercial</h6>
            </div>
            <div className='border-r border-gray-200 flex flex-col py-4 items-center justify-center'>
                <h6 className='font_bold text-[14px]'>Possession</h6>
                <h6 className='font_normal text-[12px] mt-[5px] w-[90%] text-center'>Ready for Fit-out</h6>
            </div>

        </div>
        
        <div className='w-[90%] md:w-[80%] mx-auto'>
        <h6 className='font_bold text-[15px] text-[#4190ac] my-10'>SPECIFICATIONS</h6>

        <ul>
          <li>Development Retail / Office</li>
          <li>Electronic city Next to Infosys Campus</li>
          <li>Leasable Area 2,46,337 Sq.Ft</li>
          <li>Car Parking 452</li>
          <li>Visibility Excellent visibility from the main road</li>
          <li>Cafeteria On Terrace</li>
        </ul>
        </div>

        <h6 className='font_bold text-[15px] text-center text-[#4190ac] my-10'>Floor Plans</h6>

        <div className='grid grid-cols-2 py-10 md:grid-cols-6  w-[90%] md:w-[80%] mx-auto md:gap-5'>
        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Master Plan</h6>
            <img src={master_plan} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Basement Floor</h6>
            <img src={basement_floor} className='w-[100%] h-[82%] object-cover' />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Ground Floor</h6>
            <img src={ground_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>First Floor</h6>
            <img src={first_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Second Floor</h6>
            <img src={second_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Third Floor</h6>
            <img src={third_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>4th, 5th, 6th & 7th Floor</h6>
            <img src={fourth_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Eight Floor</h6>
            <img src={eight_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>Terrace Floor</h6>
            <img src={terrace_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>TYPICAL FLOOR SECTION</h6>
            <img src={typical_floor} />
        </div>

        <div className='border border-slate-100 p-2 relative'>
            <h6 className='bg-[#4190ac]/40 text-[13px] font_bold p-[4px]'>SECTION PLAN</h6>
            <img src={section_plan} />
        </div>
        </div>


      


        <div className='w-[90%] md:w-[80%] md:flex items-start  mx-auto '>
            <a className='md:w-[50%]' href="https://www.google.com/maps?q=12.848886,77.662708&entry=gps&shorturl=1" target='_blank'>
               <img src={location_1} className='md:w-[100%] h-[100%]' />
            </a>
            <div className=' mt-10 md:mt-0 md:w-[50%] md:ml-10 '>
                 <h6 className='font_bold  text-[#4190ac] mb-2'>Distance from Key Nodes</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Next to IFIM Institutions, adjust to Infosys campus.</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>International Airport: 61 Kms.</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>Hosur main road : 800 meters .</h6>
                 <h6 className='font_normal text-[12.5px] mb-[5px]'>MG Road : 15 KM.</h6>
                 <div>
                    <h6 className='text-[11.5px] mt-10 font_bold '>CONTACT US TODAY, IF YOU ARE INTRESTED</h6>

                    <div className=' border-b pb-2 mt-2'>
                        <h6 className='text-[13px] font_bold'>Name: DEEPA K</h6>
                        <h6 className='font_normal text-[12px]'>+91 9845048969 / deepa.k@fidelituscorp.com </h6>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default ShilpaAnanya