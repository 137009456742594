import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

function GoBack() {
  const navigate = useNavigate()  
  return (
    <div>
        <div className='flex bg-[#def9ff] px-[5%] py-[14px]'>
        <div className='flex items-center cursor-pointer' onClick={()=>navigate(-1)}>
        <IoIosArrowRoundBack color='#027d9b' />
        <h6 className='text-[11px] ml-2 text-[#027d9b] font_bold'>Back to Fidelitus Exclusive</h6>
        </div>
      </div>
    </div>
  )
}

export default GoBack